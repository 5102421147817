import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SchoolIcon from '@mui/icons-material/School';
import { Link } from "react-router-dom";
import image from "./../logo-redbox.png";
import AssessmentIcon from '@mui/icons-material/Assessment';
import LanguageIcon from '@mui/icons-material/Language';
import StyleIcon from '@mui/icons-material/Style';
import CategoryIcon from '@mui/icons-material/Category';
import FactoryIcon from '@mui/icons-material/Factory';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import WorkIcon from '@mui/icons-material/Work';

const Sidebar = () => {
  return (
    <div
      className="sidebar sidbar2"
      style={{ position: "fixed", padding: "15px" }}
    >
      <div className="top">
       <Link to="/dashboard"> <img
          src={image}
          width={120}
          height={40}
          style={{ marginTop: "10px" }}
          alt=""
          className="avatar "
        /></Link>
      </div>

      <div className="center " style={{ marginTop: "10px" }}>
        <ul>
          <p className="title">MAIN</p>
          <Link to="/dashboard" className="text-d-none">
            <li>
              <DashboardIcon className="icon f-16" />
              <span className="f-16">Dashboard</span>
            </li>
          </Link>

          <Link to="/seekers" className="text-d-none">
            <li>
              <PersonIcon className="icon" />
              <span className="f-16">Seekers</span>
            </li>
          </Link>
          <Link to="/recruiters" className="text-d-none">
            <li>
            <PersonIcon className="icon" />
              <span className="f-16">Recruiters</span>
            </li>
          </Link>
          <Link to="/all-jobs" className="text-d-none">
            <li>
            <WorkIcon className="icon" />
              <span className="f-16">All Jobs</span>
            </li>
          </Link>
          <Link to="/unapprove-jobs" className="text-d-none">
            <li>
            <WorkIcon className="icon" />
              <span className="f-16">Unapproved Jobs</span>
            </li>
          </Link>

          <p className="title">Others</p>
          <Link to="/location" className="text-d-none">
            <li>
              <LocationOnIcon className="icon" />
              <span className="f-16">Location</span>
            </li>
          </Link>

          <Link to="/industry" className="text-d-none">
            <li>
              <FactoryIcon className="icon" />
              <span className="f-16">Industry</span>
            </li>
          </Link>
          <Link to="/functional-area" className="text-d-none">
            <li>
              <FactoryIcon className="icon" />
              <span className="f-16">Functional Area</span>
            </li>
          </Link>
          <Link to="/category" className="text-d-none">
            <li>
              <CategoryIcon className="icon" />
              <span className="f-16">Category</span>
            </li>
          </Link>
          <Link to="/language" className="text-d-none">
            <li>
              <LanguageIcon className="icon" />
              <span className="f-16">Language</span>
            </li>
          </Link>
          <Link to="/degree" className="text-d-none">
            <li>
              <SchoolIcon className="icon" />
              <span className="f-16">Degree</span>
            </li>
          </Link>
          <Link to="/year" className="text-d-none">
            <li>
              <CalendarMonthIcon className="icon" />
              <span className="f-16">Year</span>
            </li>
          </Link>
          <Link to="/tags" className="text-d-none">
            <li>
              <StyleIcon className="icon" />
              <span className="f-16">Tags</span>
            </li>
          </Link>
          <Link to="/skills" className="text-d-none">
            <li>
              <StyleIcon className="icon" />
              <span className="f-16">Skills</span>
            </li>
          </Link>
          {/* <p className="title">Subsription</p> */}
          {/* <Link to="/subscription" className="text-d-none">
            <li>
              <DashboardIcon className="icon f-16" />
              <span className="f-16">Subscription</span>
            </li>
          </Link> */}
          <Link to="/notification" className="text-d-none">
            <li>
              <DashboardIcon className="icon f-16" />
              <span className="f-16">Notification</span>
            </li>
          </Link>
          <p className="title">Reports</p>
          {/* <Link to="/sales-report" className="text-d-none">
            <li>
              <AssessmentIcon className="icon f-16" />
              <span className="f-16">Sales Report</span>
            </li>
          </Link> */}
          <Link to="/user-report" className="text-d-none">
            <li>
            <AssessmentIcon className="icon" />
              <span className="f-16">User Report</span>
            </li>
          </Link>
          <Link to="/postwise-report" className="text-d-none">
            <li>
              <AssessmentIcon className="icon" />
              <span className="f-16">Post-wise Report</span>
            </li>
          </Link>

          <Link to="/admins" className="text-d-none">
            <li>
              <PersonIcon className="icon" />
              <span className="f-16">Admins</span>
            </li>
          </Link>
         
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
