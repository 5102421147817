import React, { useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SalesReport = () => {

  // ------------------------------------------------CHART OPTION------------------------------------


  const option = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Sales Report",
      },
    },
  };

  // ------------------------------------------------CHART DATA------------------------------------


  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],

    datasets: [
      {
        label: "Total Sales",
        data: [0, 0, 0, 0, 0, 0],
        backgroundColor: "#5d3891",
      },
      {
        label: "Total Customer",
        data: [0, 0, 0,0, 0, 0],
        backgroundColor: "#ccabf7",
      },
    ],
  };

  // ------------------------------------------------NO ACCESS WITHOUT TOKAN------------------------------------

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10 ">
          <div className="container-fluid">
            <Navbar />
            <div className="p-2 bg-white">
              <span>
                <Link to="/dashboard" className="text-d-none">
                  Dashboard
                </Link>{" "}
                / Sales-report
              </span>
            </div>

            <div className="App">
              <Bar options={option} data={data} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesReport;
