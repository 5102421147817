import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";
import defaultimage from "./../all-image/default-image.png";

const ViewSeeker = () => {
  // ------------------------------------------------FETCH ALL locations---------------------------------------

  // const [location, setLocationlist] = useState([]);
  const [preferedLocation, setPreferedLocation] = useState([]);
  const [language, setLanguage] = useState([]);
  // useEffect(() => {
  //   axios.get("/api/locations").then((res) => {
  //     setLocationlist(res.data.data);
  //   });
  // }, []);

  // ------------------------------------------------FETCH ALL INDUSTRY---------------------------------------

  const [industrylist, setIndustrylist] = useState([]);
  useEffect(() => {
    axios.get("/api/industry").then((res) => {
      setIndustrylist(res.data.data);
    });
  }, []);

  const { id } = useParams();

  const [seeker, setSeeker] = useState("");

  // ------------------------------------------------FETCH SINGLE SEEKER---------------------------------------

  useEffect(() => {
    loadCustomer();
  }, [id]);

  const loadCustomer = async () => {
    const result = await axios.get("/api/seekers/" + id);
    setSeeker(result.data.data);
    setPreferedLocation(result.data.data?.preferredlocation);
    setLanguage(result.data.data?.seekerlanguages);
  };

  const [jobDetail, setJobDetail ] = useState("");

  useEffect(() => {
    axios
      .get("/api/seekersDetails/" + id)
      .then((res) => {
        setJobDetail(res.data.data.professionalDetails          );
        console.log(res.data.data.professionalDetails  ,"iiiiiiii");
      })
      .catch((err) => {});
  }, []);



  // ------------------------------------------------no access without tokan---------------------------------------

  console.log(seeker);

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);
  // console.log("seeker", seeker);

  return (
    
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10 ">
          <div className="container-fluid">
            <Navbar />
            <div className="p-2 bg-white">
              <span>
                <Link to="/dashboard" className="text-d-none">
                  Dashboard
                </Link>{" "}
                /{" "}
                <Link to="/seekers" className="text-d-none">
                  seeker
                </Link>{" "}
                / View
              </span>
            </div>
            <section
              className="bg-gre"
              style={{
                padding: "10px 0px 30px 0",
                minHeight: "570px",
              }}
            >
              <MDBContainer className="px-5 mt-4">
                <MDBRow>
                  <MDBCol lg="6">
                    <MDBCard className="mb-2">
                      <MDBCardBody>
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>seeker Profile</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {seeker.profile_picture == null ? (
                                <MDBCardImage
                                  src={defaultimage}
                                  alt="No Image"
                                  className=" mb-1"
                                  style={{ width: "50px", borderRadius: "50%" }}
                                  fluid
                                />
                              ) : (
                       <Link to={seeker.profile_picture} target="_blank">        <MDBCardImage
                                  src={seeker.profile_picture}
                                  alt="No Image"
                                  className=" mb-1"
                                  style={{ width: "50px", borderRadius: "50%" }}
                                  fluid
                                /></Link> 
                              )}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>seeker Name</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {seeker.name}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Mobile</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {seeker.phone}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Email Id</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {seeker.email}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Gender</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {seeker.gender}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Date of birth </MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {seeker.date_of_birth}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                     
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Location</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {seeker.current_location}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Preferred Location</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {preferedLocation.length == 0 ? (
                                "Not Selected"
                              ) : (
                                <>
                                  {preferedLocation.map((items, index) => {
                                    return (
                                      <span key={index}>
                                        {items.preferred_location}{" "}
                                        {preferedLocation.length == index + 1
                                          ? ""
                                          : ","}
                                      </span>
                                    );
                                  })}{" "}
                                </>
                              )}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                     
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Category</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                              {seeker.industry_details?.industry}
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Functional Area</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                            {seeker.functional_area_details?.title}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                  <MDBCol lg="6">
                    <MDBCard className="mb-2 ">
                      <MDBCardBody>
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Resume</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            {seeker.attach_resume == null ? (
                              "No resume"
                            ) : (
                              <Link target="_blank" to={seeker.attach_resume}>
                                {" "}
                                <button className="bg-purple text-white px-3  py-1 br-10 border-p">
                                  View
                                </button>
                              </Link>
                            )}
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Linkedin</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            {seeker.linkedin == null || seeker.linkedin == "null" ? (
                              "No link"
                            ) : (
                              <Link target="_blank" to={seeker.linkedin}>
                                {" "}
                                <button className="bg-purple text-white px-3 py-1 br-10 border-p">
                                  View
                                </button>
                              </Link>
                            )}
                          </MDBCol>
                        </MDBRow>
                        <hr />

          {seeker.marital_status  == null ? "" :  <><MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Marital status</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {seeker.marital_status}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>     <hr /> </> }            
          
                    
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Current Company</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {jobDetail?.organization == null ? "NA" : jobDetail?.organization
  }
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Designation</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {jobDetail?.designation == null  ? "NA" : jobDetail?.designation}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Salary</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {seeker.annual_salary == null ? "NA" : seeker.annual_salary + " Lac per annum" } 
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Expected Salary</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {seeker.expected_salary == null ? "NA" : seeker.expected_salary + " Lac per annum" } 
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Total Work Experience</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {seeker.experience_year + " Year" + " " +seeker.experience_month + " Months"  }
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Notice Period</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {seeker.notice_period}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                   
                        {seeker.skills == null ? (
                          ""
                        ) : (
                          <>
                            {" "}
                            <MDBRow>
                              <MDBCol sm="5">
                                <MDBCardText>skills</MDBCardText>
                              </MDBCol>
                              <MDBCol sm="6">
                                <MDBCardText className="text-muted">
                                  {seeker.skills}
                                </MDBCardText>
                              </MDBCol>
                            </MDBRow>
                            <hr />
                          </>
                        )}

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Industry</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {industrylist.map((industry, index) => (
                                <span key={index}>
                                  {seeker.industry == industry.id
                                    ? industry.industry
                                    : ""}
                                </span>
                              ))}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                     
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Language</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {language.length == 0 ? (
                                "Not Selected"
                              ) : (
                                <>
                                  {language.map((items, index) => {
                                    return (
                                      <span key={index}>
                                        {items.language}{" "}
                                        {language.length == index + 1
                                          ? ""
                                          : ","}
                                      </span>
                                    );
                                  })}{" "}
                                </>
                              )}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
           {   seeker.about == null || seeker.about == "null" ? "" :       
             <MDBContainer className="px-5 mt-4">
                <MDBRow>
                  <MDBCol lg="12">
                    <MDBCard className="mb-2 ">
                      <MDBCardBody>
                        <MDBRow>
                          <MDBCol sm="2">
                            <MDBCardText>About</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="10">
                            <MDBCardText className="text-muted">
                              {seeker.about}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer> }
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSeeker;
