import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import { BarElement,  CategoryScale,Chart as ChartJS,Legend, LinearScale,Title, Tooltip } from "chart.js";


ChartJS.register(CategoryScale, LinearScale, BarElement,Title,Tooltip,Legend);


const SeekerReport = () => {


  const tokan = typeof window !== "undefined" ? localStorage.getItem("status") : null;
  const [totalRecruiter, settotalRecruiter] = useState();
  const [totalSeeker, settotalSeeker] = useState();
  const [monthdata, setmonthdata] = useState();

  // -----------------------------------------------FETCH USER REPORT---------------------------------------------

    
    
       useEffect(() => {
          axios.get("/api/user_reports",{
            'Authorization': 'Bearer '+ tokan ,
          } ).then((res) => {
              console.log("result",res.data)
              settotalRecruiter(res.data.total_recruiter)
              settotalSeeker(res.data.total_seeker)
              setmonthdata(res.data.month)
          });
      
      }, []); 


  // -----------------------------------------------CHART OPTION----------------------------------------------


  const option={
    indexAxis:'y',
    elements:{
      bar:{
        borderWidth:1,
      }
    },
    responsive:true,
    plugins:{
      legend:{
        position:'bottom'
      },
      title:{
        display:true,
        text:'User Reports ( last 6 months )'
      }
    }
  }

  // -----------------------------------------------CHART DATA----------------------------------------------

  
  const data = {
    labels :monthdata,

    datasets: [
      {
        label: "Recruiters",
        data: totalRecruiter,
        backgroundColor: "#5d3891",
      },
      {
        label:'Seekers',
        data:totalSeeker,
        backgroundColor:'#ccabf7'
      },
  
    ],
  
  };

  // -----------------------------------------------NO ACCESS WITHOUT TOKAN----------------------------------------------

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10 ">
          <div className="container-fluid">
            <Navbar />
            <div className="p-2 bg-white">
              <span>
                <Link to="/dashboard" className="text-d-none">
                  Dashboard
                </Link>{" "}
                / Users-report
              </span>
            </div>
            <section
              className="bg-gre p-3"
              
            >
            <div className="App">
      <Bar options={option} data={data} />
    </div>
    </section>

          </div>
        </div>
      </div>
    </div>
  );
};

export default SeekerReport;
