import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";

const ViewNotifications = () => {


// ------------------------------------------------FETCH ALL LOCATION--------------------------------------------

  const [location, setLocationlist] = useState([]);
  useEffect(() => {
    axios.get("/api/locations").then((res) => {
      setLocationlist(res.data.data);
    });
  }, []);

  const { id } = useParams();

  const [notification, setNotification] = useState("");

  useEffect(() => {
    loadCustomer();
  }, []);

// ------------------------------------------------FETCH SINGLE RECRUITER BY ID--------------------------------------------


  const loadCustomer = async () => {
    const result = await axios.get("/api/get_single_notification/" + id);

    setNotification(result.data.data);
  };

// ------------------------------------------------NO ACCESS WITHOUT TOKAN--------------------------------------------
  


  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10 ">
          <div className="container-fluid">
            <Navbar />
            <div className="p-2 bg-white">
              <span>
                <Link to="/dashboard" className="text-d-none">
                  Dashboard
                </Link>{" "}
                /{" "}
                <Link to="/notification" className="text-d-none">
                 Notifications
                </Link>{" "}
                / View
              </span>
            </div>
            <section
              className="bg-gre"
              style={{
                padding: "10px 0px 30px 0",
                minHeight: "570px",
              }}
            >
              <MDBContainer className="px-5 mt-4 ">
                <MDBRow className="">
                  <MDBCol lg="12">
                    <MDBCard className="mb-2">
                      <MDBCardBody>
                 

                   
             
                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>Title</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="8">
                            <MDBCardText className="text-muted">
                              {notification.title}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>Description</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="8">
                            <MDBCardText className="text-muted">
                              {notification.description}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        <MDBRow>
                          <MDBCol sm="4">
                            <MDBCardText>User Type</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="8">
                            <MDBCardText className="text-muted">
                              {notification.user_type == 1 ? "Seeker" :"Recruiter"}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
               
                  

                
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
             
                </MDBRow>
              </MDBContainer>
        
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewNotifications;
