import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Link } from "react-router-dom";
import styled from "styled-components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import CustomeLoader from "../../components/CustomeLoader";
import defaultimage from "./../all-image/default-image.png";

const Admin = () => {
  const [admin, setadmin] = useState([]);
  const status =
  typeof window !== "undefined" ? localStorage.getItem("status") : null;
  const [rows, setRows] = useState([]);
  const [errormsg, seterrorMsg] = useState("");
  const [msg, setMsg] = useState("");
  const [filterText, setFilterText] = useState("");
  const [pending, setPending] = useState(true);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  // --------------------------------------------------FETCH ALL RECRUITER-------------------------------------------------

  useEffect(() => {
    axios
      .get("/api/show_admins" ,{
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + status,
        },
      })
      .then((res) => {
        setadmin(res.data.data);
        console.log(res.data.data);
      })

      .catch((err) => {
        console.log("error",err);


      });
  }, []);

  // ---------------------------------------------------------------------------------------------------------------------

  // --------------------------------------------------DELETE RECRUITER---------------------------------------------------

  const deleteHandler = (row) => {
    axios
      .delete("/api/delete_admin/" + row.id, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        setMsg(result.data.message);
        let newArr = admin.filter((items) => {
          return row.id !== items.id;
        });
        setadmin(newArr);
        //window.location.reload(false);
      })
      .catch((error) => {
        seterrorMsg(error.response.data.message);
        console.log(error);
      });
  };

  // ---------------------------------------------------------------------------------------------------------------------

  // ------------------------------------------------RECRUITER TABLE COLUMN-----------------------------------------------
  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "70px",
    },
    {
      name: "Email",
      selector: (row) => row.email ,
      sortable: true,
      width: "300px",
    },
    {
      name: "action",
      selector: (row) => (
        <div>
          <button
            className="bg-red text-white border-r px-2 py-1 br-10 m-1"
            onClick={() => {
              if (window.confirm("Are you sure you wish to delete this item?"))
                deleteHandler(row);
            }}
          >
            <DeleteIcon />
          </button>{" "}
        </div>
      ),
      width: "140px",
      sortable: false,
    },
  ];

  // ---------------------------------------------------------------------------------------------------------------------

  // ---------------------------------------------------NO LOGIN WITHOUT TOKEN--------------------------------------------

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  // --------------------------------------------------------------------------------------------------------------------

  // ----------------------------------------------table data loading----------------------------------------------------

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(admin);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  // -----------------------------------------------------FORM INPUT FIELD CSS--------------------------------------------

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    &:hover {
      cursor: pointer;
    }
  `;
  // -----------------------------------------------------------------------------------------------------------------

  // --------------------------------------------------------FILTER DATA FROM TABLE---------------------------------

  const filteredItems = admin.filter(
    (item) =>
     
      (item.email &&
        item.email.toLowerCase().includes(filterText.toLowerCase())) 
    
  );

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  // ----------------------------------------------------------------------------------------------------------------

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0 ">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <Navbar />

            <div className="container">
              <div className="row mt-2">
                {msg && <div className="alert alert-success">{msg}</div>}
                {errormsg && (
                  <div className="alert alert-danger">{errormsg}</div>
                )}
                <div className="col-sm-6 mt-2">
                  <TextField
                    type="text"
                    placeholder="Search...."
                    value={filterText}
                    autoFocus
                    onChange={(e) => setFilterText(e.target.value)}
                  />
                  <button
                    className="bg-purple text-white border-p m-1"
                    onClick={handleClear}
                  >
                    X
                  </button>
                </div>
                <div className="col-sm-6">
                  <div className="mt-2 text-right ">
                    <Link to="/add-admin">
                      <button className="bg-purple text-white border-p px-3 py-1 br-10 m-1">
                        + Add Admin
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="m-1">
              <DataTable
                columns={columns}
                data={filteredItems}
                defaultSortFieldId
                fixedHeaderScrollHeight="480px"
                fixedHeader
                responsive
                pagination={10}
                subHeaderAlign="right"
                highlightOnHover
                progressPending={pending}
                progressComponent={<CustomeLoader />}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Admin;
