import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";

const ViewSubscription = () => {
 
  

  const { id } = useParams();

  const [subsCription, setSubscription] = useState("");

    // ------------------------------------FETCH SINGLE SUBSCRIPTION---------------------------------------

  useEffect(() => {
    subscription();
  }, []);

  const subscription = async () => {
    const result = await axios.get("/api/get_single_subscription/" + id);

    setSubscription(result.data.data);
  };

    // ------------------------------------NO ACCESS WITHOUT TOKEN---------------------------------------




  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10 ">
          <div className="container-fluid">
            <Navbar />
            <div className="p-2 bg-white">
              <span>
                <Link to="/dashboard" className="text-d-none">
                  Dashboard
                </Link>{" "}
                /{" "}
                <Link to="/subscription" className="text-d-none">
                  Subscription
                </Link>{" "}
                / View
              </span>
            </div>
            <section
              className="bg-gre"
              style={{
                padding: "10px 0px 30px 0",
                minHeight: "570px",
              }}
            >
              <MDBContainer className="px-5 mt-4">
                <MDBRow>
                  <MDBCol lg="10" className="m-auto">
                    <MDBCard className="mb-2">
                      <MDBCardBody>
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Image</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              <MDBCardImage
                                src={subsCription.image}
                                alt="No Image"
                                className=" mb-1"
                                style={{
                                  width: "100px",
                                  border: "1px solid #e7e7e7",
                                }}
                                fluid
                              />
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>

                        <hr />
             
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Title</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {subsCription.title}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Price</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                            ₹{" "}{subsCription.price}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Description</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {subsCription.description}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                       
                   
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
              
                </MDBRow>
              </MDBContainer>
          
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSubscription;
