import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";

const ViewUnapprovejobs = () => {
  const [errormsg, seterrorMsg] = useState("");
  const [msg, setMsg] = useState("");

  // ------------------------------------------------FETCH ALL LOCATION--------------------------------------------

  const [location, setLocationlist] = useState([]);
  const [locations, setLocations] = useState([]);
  const [tags, setTags] = useState([]);
  useEffect(() => {
    axios.get("/api/locations").then((res) => {
      setLocationlist(res.data.data);
    });
  }, []);

  const { id } = useParams();

  const [singlejob, setsinglejob] = useState("");

  useEffect(() => {
    loadCustomer();
  }, []);

  // ------------------------------------------------FETCH SINGLE RECRUITER BY ID--------------------------------------------

  const loadCustomer = async () => {
    const result = await axios.get("/api/single_post/" + id);
    console.log(result.data.data);
    setsinglejob(result.data.data);
    setLocations(result.data.data.locations);
    setTags(result.data.data.tags);
  };

  // ------------------------------------------------NO ACCESS WITHOUT TOKAN--------------------------------------------
  const ApproveHandler = () => {
    console.log();
    const recruiterobj = new FormData();
    recruiterobj.append("id", singlejob.id);
    recruiterobj.append("is_approved", 1);

    axios
      .post("/api/approve_job", recruiterobj, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        setMsg(result.data.message);
        window.location.reload(true);
      })

      .catch((error) => {
        seterrorMsg(error.response.data.message);
      });
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10 ">
          <div className="container-fluid">
            <Navbar />
            <div className="p-2 bg-white">
              <span>
                <Link to="/dashboard" className="text-d-none">
                  Dashboard
                </Link>{" "}
                {/* <Link to="/unapprove-jobs" className="text-d-none">
                 Unapprove-jobs
                </Link>{" "} */}
                / View
              </span>
            </div>
            <section
              className="bg-gre"
              style={{
                padding: "10px 0px 30px 0",
                minHeight: "570px",
              }}
            >
              {msg && <div className="alert alert-success">{msg}</div>}
              {errormsg && <div className="alert alert-danger">{errormsg}</div>}
              <MDBContainer className="px-5 mt-4">
                <MDBRow>
                  <MDBCol lg="6">
                    <MDBCard className="mb-2">
                      <MDBCardBody>
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Job Title</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {singlejob.job_title}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>

                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Category</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {singlejob.category?.category}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Annual Salary </MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {singlejob.annual_salary_min +
                                "-" +
                                singlejob.annual_salary_max}{" "}
                              Lacs per annum
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Functional Area</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {singlejob.functional_area?.title}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>

                        <hr />

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Industry</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {singlejob.industry?.industry}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Tags</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {tags.map((items, index) => (
                                <span className="fs-16 fw-500 mx-1" key={index}>
                                  {items.tag}{" "}
                                  {tags.length == index + 1 ? "" : ","}
                                </span>
                              ))}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                  <MDBCol lg="6">
                    <MDBCard className="mb-2">
                      <MDBCardBody>
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Location</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {locations.map((items, index) => (
                                <span className="fs-16 fw-500 mx-1" key={index}>
                                  {items.location}{" "}
                                  {locations.length == index + 1 ? "" : ","}
                                </span>
                              ))}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>

                        <hr />

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Recruiter Type</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {singlejob.recruiter?.choose_your_company_type ==
                              0
                                ? "Company"
                                : "Consultant"}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Experiance </MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {singlejob.years_of_experience_min +
                                "-" +
                                singlejob.years_of_experience_max}{" "}
                              Year
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Passing Year</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {singlejob.graduation_year_min +
                                "-" +
                                singlejob.graduation_year_max}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Organization</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {singlejob.recruiter?.organization}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
              {/* <MDBContainer className="px-5 mt-4">
                <MDBRow>
                  <MDBCol lg="12">
                    <MDBCard className="mb-2 ">
                      <MDBCardBody>
                        <MDBRow>
                          <MDBCol sm="2">
                            <MDBCardText>Job Description</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="10">
                            <MDBCardText className="text-muted">
                            { ReactHtmlParser(singlejob.job_description) }
                              {singlejob.job_description
}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer> */}
              <MDBContainer className="px-5 mt-4">
                <MDBRow>
                  <MDBCol lg="6">
                    <MDBCard className="mb-2">
                      <MDBCardBody>
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Recruiter Profile</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {singlejob.recruiter?.profile_picture == null ? (
                                "no image"
                              ) : (
                                <MDBCardImage
                                  src={singlejob.recruiter?.profile_picture}
                                  alt="No Image"
                                  className=" mb-1"
                                  style={{
                                    width: "90px",
                                    border: "1px solid #e7e7e7",
                                  }}
                                  fluid
                                />
                              )}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>

                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Company Name</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {singlejob.recruiter?.organization}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Recruiter Name</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {singlejob.recruiter?.name}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Mobile</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {singlejob.recruiter?.phone}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Email Id</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {singlejob.recruiter?.work_email_id}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Company Type</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {singlejob.recruiter?.choose_your_company_type ==
                              0
                                ? "Company"
                                : "Consultancy"}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                  <MDBCol lg="6">
                    <MDBCard className="mb-2 ">
                      <MDBCardBody>
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Website</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <Link
                              target="_blank"
                              to={singlejob.recruiter?.website}
                            >
                              {" "}
                              <button className="bg-purple text-white px-3 py-1 br-5 border-p">
                                View
                              </button>
                            </Link>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Facebook</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <Link
                              target="_blank"
                              to={singlejob.recruiter?.facebook}
                            >
                              {" "}
                              <button className="bg-purple text-white px-3 py-1 br-5 border-p">
                                View
                              </button>
                            </Link>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Twitter</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <Link
                              target="_blank"
                              to={singlejob.recruiter?.twitter}
                            >
                              {" "}
                              <button className="bg-purple text-white px-3 py-1 br-5 border-p">
                                View
                              </button>
                            </Link>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Linkedin</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <Link
                              target="_blank"
                              to={singlejob.recruiter?.linkedin}
                            >
                              {" "}
                              <button className="bg-purple text-white px-3 py-1 br-5 border-p">
                                View
                              </button>
                            </Link>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Designation</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {singlejob.recruiter?.designation}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Location</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {location.map((citys, index) => (
                                <span key={index}>
                                  {singlejob.recruiter?.location == citys.id
                                    ? citys.location
                                    : ""}
                                </span>
                              ))}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Company Logo</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {singlejob.recruiter?.company_logo == null ? (
                                "no image"
                              ) : (
                                <MDBCardImage
                                  src={singlejob.recruiter?.company_logo}
                                  alt="No Image"
                                  className=" mb-1"
                                  style={{
                                    width: "100px",
                                    border: "1px solid #e7e7e7",
                                  }}
                                  fluid
                                />
                              )}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>

              <MDBContainer className="px-5 mt-4">
                <MDBRow>
                  <MDBCol lg="12">
                    <MDBCard className="mb-2 ">
                      <MDBCardBody>
                        <MDBRow>
                          <MDBCol sm="2">
                            <MDBCardText>About</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="10">
                            <MDBCardText className="text-muted">
                              {singlejob.recruiter?.about}
                              
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>

              {singlejob.is_approved == 0 ? (
                <div className="text-canter">
                  {" "}
                  <center>
                    <button
                      className="bg-purple text-white border-p px-2 py-1 br-5 m-1"
                      onClick={ApproveHandler}
                    >
                      approve
                    </button>
                  </center>
                </div>
              ) : (
                ""
              )}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewUnapprovejobs;
