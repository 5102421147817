import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import ViewRecruiter from "./pages/recruiters/ViewRecruiter";
import Seeker from "./pages/seeker/Seeker";
import Recruiter from "./pages/recruiters/Recruiter";
import AddRecruiter from "./pages/recruiters/AddRecruiter";
import EditRecruiter from "./pages/recruiters/EditRecruiter";
import Location from "./pages/location/Location";
import Language from "./pages/language/Language";
import Industry from "./pages/industry/Industry";
import ViewSeeker from "./pages/seeker/ViewSeeker";
import Tags from "./pages/tags/Tags";
import Category from "./pages/category/Category";
import Year from "./pages/year/Year";
import Degree from "./pages/degree/degree";
import Subscription from "./pages/subscription/Subscription";
import SeekerReport from "./pages/reports/SeekerReport";
import RecruiterReport from "./pages/reports/RecruiterReport";
import SalesReport from "./pages/reports/SalesReport";
import AddSubscription from "./pages/subscription/AddSubscription";
import EditSubscription from "./pages/subscription/EditSubscription";
import ViewSubscription from "./pages/subscription/ViewSubScription";
import Skills from "./pages/skills/Skills";
import Notifications from "./pages/notification/Notifications";
import AddNotifications from "./pages/notification/AddNotification";
import EditNotifications from "./pages/notification/EditNotification";
import ViewNotifications from "./pages/notification/ViewNotification";
import Unapprovejobs from "./pages/unapprovejobs/Unapprovejobs";
import ViewUnapprovejobs from "./pages/unapprovejobs/ViewUnapprovejobs";
import FunctionalArea from "./pages/functionalarea/FunctionalArea";
import Admin from "./pages/admins/Admin";
import AddAdmin from "./pages/admins/AddAdmin";
import AllJobs from "./pages/unapprovejobs/AllJobs";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Login />} />
          <Route path="/dashboard" element={<Home />} />
        </Route>
        <Route path="/seekers">
          <Route index element={<Seeker />} />
        </Route>
        <Route path="/unapprove-jobs">
          <Route index element={<Unapprovejobs />} />
        </Route>
        <Route path="/all-jobs">
          <Route index element={<AllJobs />} />
        </Route>
        <Route path="/jobs/view/:id">
          <Route index element={<ViewUnapprovejobs />} />
        </Route>
        <Route path="/user-report">
          <Route index element={<SeekerReport />} />
        </Route>
        <Route path="/notification">
          <Route index element={<Notifications />} />
        </Route>
        <Route path="/recruiters">
          <Route index element={<Recruiter />} />
        </Route>
        <Route path="/postwise-report">
          <Route index element={<RecruiterReport />} />
        </Route>
        <Route path="/sales-report">
          <Route index element={<SalesReport />} />
        </Route>
        <Route path="/admins">
          <Route index element={<Admin />} />
        </Route>
        <Route path="/location">
          <Route index element={<Location />} />
        </Route>
        <Route path="/industry">
          <Route index element={<Industry/>} />
        </Route>
        <Route path="/functional-area">
          <Route index element={<FunctionalArea/>} />
        </Route>
        <Route path="/subscription">
          <Route index element={<Subscription/>} />
        </Route>
        <Route path="/tags">
          <Route index element={<Tags/>} />
        </Route>
        <Route path="/skills">
          <Route index element={<Skills/>} />
        </Route>
        <Route path="/degree">
          <Route index element={<Degree/>} />
        </Route>
        <Route path="/year">
          <Route index element={<Year/>} />
        </Route>
        <Route path="/language">
          <Route index element={<Language/>} />
        </Route>
        <Route path="/category">
          <Route index element={<Category/>} />
        </Route>
        <Route path="/add-recruiter">
          <Route index element={<AddRecruiter />} />
        </Route>
        <Route path="/add-admin">
          <Route index element={<AddAdmin />} />
        </Route>
      
        <Route path="/add-subscription">
          <Route index element={<AddSubscription />} />
        </Route>
        <Route path="/add-notifications">
          <Route index element={<AddNotifications />} />
        </Route>
       
        <Route path="/recruiters/view/:id">
          <Route index element={<ViewRecruiter />} />
        </Route>
        <Route path="/seeker/view/:id">
          <Route index element={<ViewSeeker />} />
        </Route>

        <Route path="/recruiters/edit/:id">
          <Route index element={<EditRecruiter />} />
        </Route>
        <Route path="/notification/edit/:id">
          <Route index element={<EditNotifications />} />
        </Route>
        <Route path="/subscription/edit/:id">
          <Route index element={<EditSubscription />} />
        </Route>
        <Route path="/subscription/view/:id">
          <Route index element={<ViewSubscription />} />
        </Route>
        <Route path="/notification/view/:id">
          <Route index element={<ViewNotifications />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
