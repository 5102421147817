import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import styled from "styled-components";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomeLoader from "../../components/CustomeLoader";

const Degree = () => {
  const [alldegree, setAlldegree] = useState([]);
  const [show, setshow] = useState(0);
  const [degreedata, setdegreedata] = useState("");
  const [degreeid, setdegreeid] = useState("");
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);


  // ------------------------------------------------------------------------------------------------

  // --------------------------------------------------UPDATE DEGREE------------------------------------
  const handleUpdate = async (e) => {
    e.preventDefault();
    axios
      .put("/api/update_degree/" + degreeid, {
        degree: degreedata,
        headers: {
          Accept: "application/json",
        },
      })
      .then((result) => {
        window.location.reload(false);
      })
      .catch((error) => {
      });
  };

  // --------------------------------------------------ADD DEGREE------------------------------------


  const handleAddDegree = async (e) => {
    e.preventDefault();
    const degreeobj = new FormData();

    degreeobj.append("degree", degreedata);
    axios
      .post("/api/create_degree", degreeobj, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((result) => {
        window.location.reload(false);
      })
      .catch((error) => {
      });
  };

  // --------------------------------------------------FETCH ALL DEGREE------------------------------------


  useEffect(() => {
    axios
      .get("/api/get_all_degree")
      .then((res) => {
        setAlldegree(res.data.data);
        console.log(res);
      })
      .catch((err) => {});
  }, []);

  // --------------------------------------------------DELETE SINGLE DEGREE------------------------------------


  const deleteHandler = (row) => {
    alert("Are You Sure...... !");
    axios
      .delete("/api/delete_degree/" + row.id, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        let newArr = alldegree.filter((items) => {
          return row.id !== items.id;
        });
        setAlldegree(newArr);
      })
      .catch((error) => {
      });
  };

  // --------------------------------------------------EDIT SINGLE DEGREE ROW DATA------------------------------------


  const editHandler = (row) => {
    setshow(1);
    setdegreedata(row.degree);
    setdegreeid(row.id);
  };
  const addhandle = () => {
    setshow(2);
  };

  // --------------------------------------------------------------------------------------------------------

  // ------------------------------------------------DEGREE TABLE COLUMN---------------------------------------
  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      width: "70px",
    },

    {
      name: "Degree",
      selector: (row) => <div>{row.degree}</div>,
      sortable: true,
      width: "250px",
    },

    {
      name: "action",
      selector: (row) => (
        <div>
          <button
            onClick={() => editHandler(row)}
            className="bg-purple text-white border-p px-2 py-1 br-10 m-1"
          >
            <EditIcon />
          </button>
          <button
            className="bg-red text-white border-r px-2 py-1 br-10 m-1"
            onClick={() => {
              if (window.confirm("Are you sure you wish to delete this degree?"))
                deleteHandler(row);
            }}
          >
            <DeleteIcon />
          </button>{" "}
        </div>
      ),
      width: "200px",
      sortable: false,
    },
  ];

  // --------------------------------------------------------------------------------------------------

  // ---------------------------------------------------NO LOGIN WITHOUT TOKEN--------------------------------

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  // -----------------------------------------------------------------------------------------------------------

  // ----------------------------------------------table data loading-----------------------------------------------

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(alldegree);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  // -----------------------------------------------------FORM INPUT FIELD CSS------------------------------------

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
      cursor: pointer;
    }
  `;

  // ----------------------------------------------------------------------------------------------------------

  // --------------------------------------------------------FILTER DATA FROM TABLE---------------------------------

  const filteredItems = alldegree.filter(
    (item) =>
      item.degree &&
      item.degree.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  // ----------------------------------------------------------------------------------------------------------------

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0 ">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <Navbar />

            <div className="container">
              <div className="row mt-2">
                <div className="col-sm-6 mt-2">
                  {show == 0 ? (
                    <>
                      {" "}
                      <TextField
                        type="text"
                        placeholder="Search...."
                        value={filterText}
                        autoFocus
                        onChange={(e) => setFilterText(e.target.value)}
                      />
                      <button
                        className="bg-purple text-white border-p m-1"
                        onClick={handleClear}
                      >
                        X
                      </button>{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {show == 1 ? (
                    <div>
                      <span>Edit Degree :</span>{" "}
                      <input
                        type="text"
                        value={degreedata}
                        name="degree"
                        onChange={(e) => setdegreedata(e.target.value)}
                      />{" "}
                      <button
                        className="bg-purple text-white border-p "
                        onClick={handleUpdate}
                      >
                        Update
                      </button>
                    </div>
                  ) : (
                    ""
                  )}

                  {show == 2 ? (
                    <div>
                      <span>Add Degree :</span>{" "}
                      <input
                        type="text"
                        name="degree"
                        onChange={(e) => setdegreedata(e.target.value)}
                      />{" "}
                      <button
                        className="bg-purple text-white border-p "
                        onClick={handleAddDegree}
                      >
                        Submit
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-sm-6">
                  <div className="mt-2 text-right ">
                    <button
                      className="bg-purple text-white border-p px-3 py-1 br-10 m-1"
                      onClick={addhandle}
                    >
                      + Add Degree
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="m-1">
              <DataTable
                columns={columns}
                data={filteredItems}
                defaultSortFieldId
                fixedHeaderScrollHeight="400px"
                fixedHeader
                responsive
                pagination={10}
                subHeaderAlign="right"
                highlightOnHover
                progressPending={pending}
                progressComponent={<CustomeLoader />}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Degree;
