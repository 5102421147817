import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import styled from "styled-components";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomeLoader from "../../components/CustomeLoader";

const Tags = () => {
  const [tags, settags] = useState([]);
  const [show, setshow] = useState(0);
  const [tagsdata, settagsdata] = useState("");
  const [tagsid, settagsid] = useState("");
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);



  // ------------------------------------------------------------------------------------------------

  // --------------------------------------------------UPDATE TAGS-----------------------------------
  const handleUpdate = async (e) => {
    e.preventDefault();
    axios
      .put("/api/update_tag/" + tagsid,  {
        tags:tagsdata,
        headers: {
          Accept: "application/json",
        },
      })
      .then((result) => {
        console.log(result);
        window.location.reload(false)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // ------------------------------------CRATE TAGS---------------------------------------

  const handleAddTags = async (e) => {
    e.preventDefault();
    const tagsobj = new FormData();

    tagsobj.append("tags", tagsdata);
    axios
      .post("/api/create_tag", tagsobj, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((result) => {
        console.log(result);
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // ------------------------------------FETCH ALL TAGS---------------------------------------

  useEffect(() => {
    axios
      .get("/api/get_all_tag")
      .then((res) => {
        settags(res.data.data);
        console.log(res);
      })
      .catch((err) => {});
  }, []);

  // ------------------------------------DELETE TAGS---------------------------------------

  const deleteHandler = (row) => {
    axios
      .delete("/api/delete_tag/" + row.id, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        console.log(result)
        let newArr = tags.filter((items) => {
          return row.id !== items.id;
        });
        settags(newArr);
      })
      .catch((error) => {
        console.log(error);
      });
  };

    // ------------------------------------EDIT TAG ROW DATA ---------------------------------------

  const editHandler = (row) => {
    setshow(1);
    settagsdata(row.tags);
    settagsid(row.id);
  };

  const addhandle = () => {
    setshow(2);
  };

  // --------------------------------------------------------------------------------------------------------
  console.log(tagsdata);
  // ------------------------------------------------TAGS TABLE COLUMN---------------------------------------
  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      width: "70px",
    },

    {
      name: "Tags",
      selector: (row) => <div>{row.tags}</div>,
      sortable: true,
      width: "250px",
    },

    {
      name: "action",
      selector: (row) => (
        <div>
          <button
            onClick={() => editHandler(row)}
            className="bg-purple text-white border-p px-2 py-1 br-10 m-1"
          >
            <EditIcon />
          </button>
          <button
            className="bg-red text-white border-r px-2 py-1 br-10 m-1"
            onClick={() => { if (window.confirm('Are you sure you wish to delete this tag?')) deleteHandler(row) }}
          >
            <DeleteIcon />
          </button>{" "}
        </div>
      ),
      width: "200px",
      sortable: false,
    },
  ];

  // --------------------------------------------------------------------------------------------------

  // ---------------------------------------------------NO LOGIN WITHOUT TOKEN--------------------------------

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  // -----------------------------------------------------------------------------------------------------------

  // ----------------------------------------------table data loading-----------------------------------------------

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(tags);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  // -----------------------------------------------------FORM INPUT FIELD CSS------------------------------------

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    &:hover {
      cursor: pointer;
    }
  `;

  // ----------------------------------------------------------------------------------------------------------

  // --------------------------------------------------------FILTER DATA FROM TABLE---------------------------------

  const filteredItems = tags.filter(
    (item) =>
      item.tags && item.tags.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  // ----------------------------------------------------------------------------------------------------------------

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0 ">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <Navbar />

            <div className="container">
              <div className="row mt-2">
                <div className="col-sm-6 mt-2">
                  {show == 0 ? (
                    <>
                      {" "}
                      <TextField
                        type="text"
                        placeholder="Search...."
                        value={filterText}
                        autoFocus
                        onChange={(e) => setFilterText(e.target.value)}
                      />
                      <button
                        className="bg-purple text-white border-p m-1"
                        onClick={handleClear}
                      >
                        X
                      </button>{" "}
                    </>
                  ) : (
                    ""
                  )}
                  
            {show == 1 ? (
              <div>
                <span>Edit Tags :</span>{" "}
                <input
                  type="text"
                  value={tagsdata}
                  name="tags"
                  onChange={(e) => settagsdata(e.target.value)}
                />{" "}
                <button
                  className="bg-purple text-white border-p "
                  onClick={handleUpdate}
                >
                  Update
                </button>
              </div>
            ) : (
              ""
            )}

            {show == 2 ? (
              <div>
                <span>Add Tags :</span>{" "}
                <input
                  type="text"
                  name="tags"
                  onChange={(e) => settagsdata(e.target.value)}
                />{" "}
                <button
                  className="bg-purple text-white border-p "
                  onClick={handleAddTags}
                >
                  Submit
                </button>
              </div>
            ) : (
              ""
            )}
                </div>
                <div className="col-sm-6">
                  <div className="mt-2 text-right ">
                    <button
                      className="bg-purple text-white border-p px-3 py-1 br-10 m-1"
                      onClick={addhandle}
                    >
                      + Add Tags
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="m-1">
              <DataTable
                columns={columns}
                data={filteredItems}
                defaultSortFieldId
                fixedHeaderScrollHeight="400px"
                fixedHeader
                responsive
                pagination={10}
                subHeaderAlign="right"
                highlightOnHover
                progressPending={pending}
                progressComponent={<CustomeLoader />}
              />
            </div>

          </div>
        </div>
      </div>
    </>
  );
};
export default Tags;
