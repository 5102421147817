import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import axios from "axios";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";

const EditRecruiter = () => {
  const { id } = useParams();

// ------------------------------------------------FETCH ALL LOCATION--------------------------------------------


  const [location, setLocationlist] = useState([]);
  useEffect(() => {
    axios.get("/api/locations").then((res) => {
      setLocationlist(res.data.data);
    });
  }, []);

  const companytype = [
    {
      value: "0",
      name: "Company",
    },
    {
      value: "1",
      name: "Consltant",
    },
  ];

  const [errormsg, seterrorMsg] = useState("");
  const [msg, setMsg] = useState("");
  const [profileImg, setProfileImg] = useState({
    image: "",
  });
  const [companyImg, setCompanyImg] = useState({
    image: "",
  });
  const handleImage1 = (e) => {
    setProfileImg({ image: e.target.files[0] });
  };
  const handleImage2 = (e) => {
    setCompanyImg({ image: e.target.files[0] });
  };



  const [singlerecruiter, setrecruiter] = useState(""
  
  );

// ------------------------------------------------FETCH SINGLE RECRUITER BY ID--------------------------------------------


  useEffect(() => {
    axios.get("/api/recruiter/" + id).then((res) => {
      setrecruiter(res.data.data);
    });
  }, [id]);

// ------------------------------------------------UPDATE SINGLE RECRUITER --------------------------------------------


  const handleChange = (e) => {
    e.persist();
    setrecruiter({ ...singlerecruiter, [e.target.name]: e.target.value });
  };

  const updaterecruiterInfos = async (e) => {
    e.preventDefault();
    const recruiterobj = new FormData();
    recruiterobj.append("name", singlerecruiter.name);
    recruiterobj.append("company_name", singlerecruiter.company_name);
    recruiterobj.append(
      "choose_your_company_type",
      singlerecruiter.choose_your_company_type
    );
    recruiterobj.append("work_email_id", singlerecruiter.work_email_id);
    recruiterobj.append("organization", singlerecruiter.organization);
    recruiterobj.append("phone", singlerecruiter.phone);
    recruiterobj.append("designation", singlerecruiter.designation);
    recruiterobj.append("website", singlerecruiter.website);
    recruiterobj.append("location", singlerecruiter.location);
    recruiterobj.append("facebook", singlerecruiter.facebook);
    recruiterobj.append("twitter", singlerecruiter.twitter);
    recruiterobj.append("linkedin", singlerecruiter.linkedin);
    recruiterobj.append("about", singlerecruiter.about);
    recruiterobj.append("verified", 1);
    recruiterobj.append("profile_picture", profileImg.image);
    recruiterobj.append("company_logo", companyImg.image);
    recruiterobj.append("_method", "put");

    axios
      .post("/api/update_recruiter/" + id, recruiterobj, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((result) => {
        console.log(result);
        setMsg(result.data.message)
      })
      .catch((error) => {
        seterrorMsg(error.response.data.message);
        console.log(error);
      });
  };

 
console.log(singlerecruiter.location)
// ------------------------------------------------NO ACCESS WITHOUT TOKAN --------------------------------------------


  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10 ">
          <div className="container-fluid">
            <Navbar />
            <div className="p-2 bg-white">
              <span>
                <Link to="/dashboard" className="text-d-none">
                  Dashboard
                </Link>{" "}
                /{" "}
                <Link to="/recruiters" className="text-d-none">
                  Recruiters
                </Link>{" "}
                / Edit
              </span>
            </div>
            <section
              className="bg-gre"
              style={{
                padding: "10px 0px 30px 0",
                minHeight: "570px",
              }}
            >
                   {msg && <div className="alert alert-success">{msg}</div>}
                   {errormsg && <div className="alert alert-danger">{errormsg}</div>}
              <MDBContainer
                className="px-5 mt-4"
                style={{ alignItems: "center" }}
              >
                <MDBRow>
                  <MDBCol lg="8" style={{ margin: "auto" }}>
                    <MDBCard className="mb-2 ">
                      <MDBCardBody>
                        <MDBRow>
                          <div className="container m-0">
                            <div className="row p-3 m-0">
                              <div className="col-12 px-3">
                                <MDBRow>
                                  <MDBCol sm="4">
                                    <MDBCardText>Company Logo</MDBCardText>
                                  </MDBCol>
                                  <MDBCol sm="3">
                                    <MDBCardText className="text-muted">
                                      <img
                                        alt=""
                                        width="70"
                                        height="70"
                                        src={singlerecruiter.company_logo}
                                        style={{ borderRadius: "50%" }}
                                      />
                                    </MDBCardText>
                                  </MDBCol>

                                  <MDBCol sm="5">
                                    <MDBCardText className="text-muted">
                                      <input
                                        id="company"
                                        onChange={handleImage2}
                                        required
                                        name="company_logo"
                                        type="file"
                                      />
                                    </MDBCardText>
                                  </MDBCol>
                                </MDBRow>

                                <MDBRow className="my-5">
                                  <MDBCol sm="4">
                                    <MDBCardText>Profile</MDBCardText>
                                  </MDBCol>
                                  <MDBCol sm="3">
                                    <MDBCardText className="text-muted">
                                      <img
                                        alt=""
                                        width="70"
                                        height="70"
                                        src={singlerecruiter.profile_picture}
                                        style={{ borderRadius: "50%" }}
                                      />
                                    </MDBCardText>
                                  </MDBCol>
                                  <MDBCol sm="5">
                                    <MDBCardText className="text-muted">
                                      <input
                                        id="profile"
                                        onChange={handleImage1}
                                        required
                                        name="profile_picture"
                                        type="file"
                                      />
                                    </MDBCardText>
                                  </MDBCol>
                                </MDBRow>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">
                                    Company Type
                                  </span>
                                </div>
                                <div className="mt-2">
                                  <select
                                    className="
                w-100 height-55 border-c"
                                    required
                                    name="choose_your_company_type"
                                    onChange={handleChange}
                                  >
                                    <option>select</option>
                                    {companytype.map((items, index) => (
                                      <option
                                        selected={
                                          singlerecruiter.choose_your_company_type ==
                                          items.value
                                        }
                                        key={index}
                                        value={items.value}
                                      >
                                        {items.name}
                                      </option>
                                    ))}
                                  </select>{" "}
                                </div>

                                <div className="mt-3">
                                  <span className="fw-600 fs-15">
                                    Company Name
                                  </span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    type="text"
                                    name="company_name"
                                    required
                                    value={singlerecruiter.company_name}
                                    onChange={handleChange}
                                    placeholder="enter company name"
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">Name</span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    type="text"
                                    name="name"
                                    required
                                    value={singlerecruiter.name}
                                    onChange={handleChange}
                                    placeholder="enter name"
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">
                                    Phone Number
                                  </span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    type="text"
                                    name="phone"
                                    required
                                    value={singlerecruiter.phone}
                                    onChange={handleChange}
                                    placeholder="000-0000-000"
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">Email Id</span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    value={singlerecruiter.work_email_id}
                                    type="text"
                                    name="work_email_id"
                                    required
                                    onChange={handleChange}
                                    placeholder="enter email id"
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">Location</span>
                                </div>
                                <div className="mt-2">
                                  <select
                                    className="
                w-100 height-55 border-c"
                                    name="location"
                                    required
                                    onChange={handleChange}
                                  >
                                    <option>Select</option>
                                    {location.map((citys, index) => (
                                      <option
                                        selected={
                                          singlerecruiter.location == citys.id
                                        }
                                        key={index}
                                        value={citys.id}
                                      >
                                        {citys.location}
                                      </option>
                                    ))}
                                  </select>{" "}
                                </div>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">
                                    Designation
                                  </span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    onChange={handleChange}
                                    value={singlerecruiter.designation}
                                    type="text"
                                    name="designation"
                                    required
                                    placeholder=""
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>

                                <div className="mt-3">
                                  <span className="fw-600 fs-15">
                                    Organisation
                                  </span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    name="organization"
                                    id="outlined-basic"
                                    onChange={handleChange}
                                    required
                                    value={singlerecruiter.organization}
                                    type="text"
                                    placeholder=""
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">Website</span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    required
                                    onChange={handleChange}
                                    value={singlerecruiter.website}
                                    name="website"
                                    type="text"
                                    placeholder=""
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>

                                <div className="mt-3">
                                  <span className="fw-600 fs-15">Linkedin</span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    type="text"
                                    placeholder=""
                                    value={singlerecruiter.linkedin}
                                    className="w-100"
                                    name="linkedin"
                                    onChange={handleChange}
                                    variant="outlined"
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <LinkedInIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </div>

                                <div className="mt-3">
                                  <span className="fw-600 fs-15">Twitter</span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    type="text"
                                    placeholder=""
                                    className="w-100"
                                    value={singlerecruiter.twitter}
                                    name="twitter"
                                    onChange={handleChange}
                                    variant="outlined"
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <TwitterIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </div>

                                <div className="mt-3">
                                  <span className="fw-600 fs-15">Facebook</span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    type="text"
                                    placeholder=""
                                    className="w-100"
                                    value={singlerecruiter.facebook}
                                    name="facebook"
                                    onChange={handleChange}
                                    variant="outlined"
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <FacebookIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </div>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">About</span>
                                </div>
                                <div className="mt-2 ">
                                  <TextField
                                    id="outlined-basic"
                                    onChange={handleChange}
                                    type="text"
                                    required
                                    name="about"
                                    value={singlerecruiter.about}
                                    rows={5}
                                    multiline
                                    placeholder=""
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>
                               
                     

                                <div className="row my-3">
                                  <center>
                                    {" "}
                                    <button
                                      className="px-5 py-2 text-white bg-purple border-p br-5 "
                                      onClick={updaterecruiterInfos}
                                    >
                                      Update{" "}
                                    </button>
                                  </center>
                                </div>
                              </div>
                              <div className="col-sm-2"></div>
                            </div>
                          </div>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditRecruiter;
