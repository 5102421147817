import Table from "../../components/table/Table";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Widget from "../../components/widget/Widget";
import Featured from "../../components/featured/Featured";
import Chart from "../../components/chart/Chart";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


const Home = () => {
  // ------------------------------------------without token no access by path-------------------------------------
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10">
          <div className="container-fluid p-0">
            <Navbar />
            
            <div className="home">
              <div className="homeContainer">
                <div className="widgets">
                  <Widget type="user" />
                  <Widget type="order" />
                  <Widget type="earning" />
                  <Widget type="balance" />
                </div>
                <div className="charts">
                  <Featured />
                  <Chart title="Last 6 Months (Revenue)" aspect={2 / 1} />
                </div>
                {/* <div className="listContainer">
                  <div className="listTitle">Lastest Subscription</div>
                  <LatestOrder />
                </div>
                <div className="listContainer">
                  <div className="listTitle">Lastest Transaction</div>
                  <Table />
                </div> */}

               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
