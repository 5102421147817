// import "bootstrap/dist/css/bootstrap.min.css";
// import image from "./../all-image/redbox-image.png";
// import Navbar from "react-bootstrap/Navbar";
// import { useState } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import { useEffect } from "react";

// const Login = () => {
//   const navigate = useNavigate();
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [msg, setMsg] = useState("");

//   const handleEmail = (e) => {
//     setEmail(e.target.value);
//   };

//   const handlePassword = (e) => {
//     setPassword(e.target.value);
//   };

// //----------------------------------------------------- USER LOGIN --------------------------------------------

//   const handleSubmit = () => {
//     axios
//       .post("/api/admin/login", {
//         email: email,
//         password: password,
//         headers: {
//           accept: "application/json",
//         },
//       })
//       .then((result) => {
//         localStorage.setItem("status", result.data.token);
//         navigate("/dashboard");
//       })
//       .catch((error) => {
      
//         setMsg(error.response.data.message);

      
//       });
//   };

//   // --------------------------------------------------no access by path without token--------------------------

//   useEffect(() => {
//     if (!localStorage.getItem("status")) {
//       navigate("/");
//     }
//   }, []);

//   // -------------------------------------------------------------------------------------------------------------

//   return (
//     <>
//       <div className="container-fluid">
//         <Navbar
//           expand="lg"
//           variant="light"
//           bg="light"
//           style={{ height: "80px" }}
//         >
//           <Navbar.Brand
//             style={{
//               marginLeft: "auto",
//               marginRight: "auto",
//               fontSize: "25px",
//               color: "red",
//             }}
//           >
//             {" "}
//             <img src={image} alt="Logo" width={150} />
//           </Navbar.Brand>
//         </Navbar>

//       </div>

//       <div className="container">
//         <div className="row">
//           <div className="col-sm-6  login-image text-center mt-auto">
//             <img src={image} alt="Logo" width={500} />
//           </div>
//           <div className="col-sm-6 mt-5">
//             <div className="Auth-form">
//               {msg && <div className="alert alert-danger">{msg}</div>}
//               <div className="Auth-form-content">
//                 <h3 className="Auth-form-title text-purple" >
//                   Admin Login
//                 </h3>
//                 <div className="form-group mt-3 d-flex-column">
//                   <label>Email -:</label>
//                   <input
//                     type="email"
//                     className="form-control mt-1 login-input"
//                     placeholder="Enter email"
//                     onChange={handleEmail}
//                     value={email}
//                   />
//                 </div>

//                 <div className="form-group mt-3 d-flex-column">
//                   <label>Password -:</label>
//                   <input
//                     type="password"
//                     className="form-control mt-1 login-input"
//                     placeholder="Enter password"
//                     onChange={handlePassword}
//                     value={password}
//                   />
//                 </div>
//                 <div className="submit-btn">
//                   <button className="bg-purple border-p br-10 py-1 px-3 text-white" onClick={handleSubmit}>
//                     Login
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Login;

import "bootstrap/dist/css/bootstrap.min.css";
import image from "./../all-image/redbox-image.png";
import Navbar from "react-bootstrap/Navbar";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

//----------------------------------------------------- USER LOGIN --------------------------------------------

  const handleSubmit = () => {
    axios
      .post("/api/admin/login", {
        email: email,
        password: password,
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        localStorage.setItem("status", result.data.token);
        navigate("/dashboard");
      })
      .catch((error) => {
      
        setMsg(error.response.data.message);

      
      });
  };

  // --------------------------------------------------no access by path without token--------------------------

  useEffect(() => {
    if (localStorage.getItem("status")) {
      navigate("/dashboard");
    }
  }, []);

  // -------------------------------------------------------------------------------------------------------------

  return (
    <>
      <div className="container-fluid">
        {/* <Navbar
          expand="lg"
          variant="light"
          bg="light"
          style={{ height: "80px" }}
        >
          <Navbar.Brand
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              fontSize: "25px",
              color: "red",
            }}
          >
            {" "}
            <img src={image} alt="Logo" width={150} />
          </Navbar.Brand>
        </Navbar> */}

      </div>

      <div className="container-fluid">
        <div className="row " style={{minHeight:"667px"}}>
          <div className="col-sm-6    d-flex bg-purple" style={{justifyContent:"center",alignItems:"center"}}>
            <img src={image} className="" alt="Logo" width={400} height={150} />
          </div>
          <div className="col-sm-6 d-flex" style={{justifyContent:"center",alignItems:"center"}}>
            <div className="Auth-form">
              {msg && <div className="alert alert-danger">{msg}</div>}
              <div className="Auth-form-content">
                <h3 className="Auth-form-title text-purple" >
                  Admin Login
                </h3>
                <div className="form-group mt-3 d-flex-column">
                  <label>Email -:</label>
                  <input
                    type="email"
                    className="form-control mt-1 login-input"
                    placeholder="Enter email"
                    onChange={handleEmail}
                    value={email}
                  />
                </div>

                <div className="form-group mt-3 d-flex-column">
                  <label>Password -:</label>
                  <input
                    type="password"
                    className="form-control mt-1 login-input"
                    placeholder="Enter password"
                    onChange={handlePassword}
                    value={password}
                  />
                </div>
                <div className="submit-btn">
                  <button className="bg-purple border-p br-10 py-1 px-3 text-white" onClick={handleSubmit}>
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

