import "./widget.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { Link } from "react-router-dom";

const Widget = ({ type }) => {
  let data;
  const [totaldata, settotaldata] = useState("");

  useEffect(() => {
    axios
      .get("/api/get_total")
      .then((res) => {
        settotaldata(res.data);
        console.log(res);
      })
      .catch((err) => {});
  }, []);
  console.log(totaldata)

  //temporary
  
  const diff = 20;

  switch (type) {
    case "user":
      data = {
        title: "SEEKER",
       amount : totaldata.total_seekers,
        link: "View all seeker",
        linkaddress:"/seekers",
        linkto:true,
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "order":
      data = {
        title: "RECRUITER",
        amount : totaldata.total_recruiter,
        link: "View all recruiter",
        linkaddress:"/recruiters",
        linkto:true,
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "earning":
      data = {
        title: "JOB POST",
        amount : totaldata.total_jobpost,
        link: "View all job-post",
        linkaddress:"/recruiter",
        linkto:false,
        icon: (
          <MonetizationOnOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    case "balance":
      data = {
        title: "APPLICATION",
        amount : totaldata.total_applications,
        link: "View all application",
        linkaddress:"/recruiter",
        linkto:false,
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
         {data.amount}
        </span>
{data.linkto  && <Link to={data.linkaddress} className="text-d-none link"> <span >{data.link}</span></Link>}
      </div>
      <div className="right">
        <div className="percentage positive">
          <KeyboardArrowUpIcon />
        
        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
