import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";
import defaultimage from "./../all-image/default-image.png";

const Viewrecruiter = () => {


// ------------------------------------------------FETCH ALL LOCATION--------------------------------------------

  const [location, setLocationlist] = useState([]);
  useEffect(() => {
    axios.get("/api/locations").then((res) => {
      setLocationlist(res.data.data);
    });
  }, []);

  const { id } = useParams();

  const [recruiter, setRecruiter] = useState("");

  useEffect(() => {
    loadCustomer();
  }, []);

// ------------------------------------------------FETCH SINGLE RECRUITER BY ID--------------------------------------------


  const loadCustomer = async () => {
    const result = await axios.get("/api/recruiter/" + id);
console.log(result)
    setRecruiter(result.data.data);
  };

// ------------------------------------------------NO ACCESS WITHOUT TOKAN--------------------------------------------
  


  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10 ">
          <div className="container-fluid">
            <Navbar />
            <div className="p-2 bg-white">
              <span>
                <Link to="/dashboard" className="text-d-none">
                  Dashboard
                </Link>{" "}
                /{" "}
                <Link to="/recruiters" className="text-d-none">
                  Recruiters
                </Link>{" "}
                / View
              </span>
            </div>
            <section
              className="bg-gre"
              style={{
                padding: "10px 0px 30px 0",
                minHeight: "570px",
              }}
            >
              <MDBContainer className="px-5 mt-4">
                <MDBRow>
                  <MDBCol lg="6">
                    <MDBCard className="mb-2">
                      <MDBCardBody>
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Company Logo</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
         {recruiter.company_logo == null ?     <MDBCardImage
                                src={defaultimage}
                                alt="No Image"
                                className=" mb-1"
                                style={{
                                  width: "70px",
                                  border: "1px solid #e7e7e7",
                                }}
                                fluid
                              /> :   <MDBCardImage
                              src={recruiter.company_logo}
                              alt="No Image"
                              className=" mb-1"
                              style={{
                                width: "70px",
                                border: "1px solid #e7e7e7",
                              }}
                              fluid
                            /> }                    
       
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>

                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Recruiter Profile</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                            {recruiter.profile_picture == null ?     <MDBCardImage
                                src={defaultimage}
                                alt="No Image"
                                className=" mb-1"
                                style={{
                                  width: "70px",
                                  border: "1px solid #e7e7e7",
                                }}
                                fluid
                              /> :                         <MDBCardImage
                                src={recruiter.profile_picture}
                                alt="No Image"
                                className=" mb-1"
                                style={{ width: "70px" }}
                                fluid
                              /> }
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>

                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Company Name</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {recruiter.organization}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Recruiter Name</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {recruiter.name}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Mobile</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {recruiter.phone}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Email Id</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {recruiter.work_email_id}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />

                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Recruiter Type</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {recruiter.choose_your_company_type == 0
                                ? "Company"
                                : "Consultancy"}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                  <MDBCol lg="6">
                    <MDBCard className="mb-2 ">
                      <MDBCardBody>
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Website</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <Link target="_blank" to={recruiter.website}>
                              {" "}
                              <button className="bg-purple text-white px-3 py-1 br-10 border-p">
                                View
                              </button>
                            </Link>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Facebook</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <Link target="_blank" to={recruiter.facebook}>
                              {" "}
                              <button className="bg-purple text-white px-3 py-1 br-10 border-p">
                                View
                              </button>
                            </Link>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Twitter</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <Link target="_blank" to={recruiter.twitter}>
                              {" "}
                              <button className="bg-purple text-white px-3 py-1 br-10 border-p">
                                View
                              </button>
                            </Link>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Linkedin</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <Link target="_blank" to={recruiter.linkedin}>
                              {" "}
                              <button className="bg-purple text-white px-3 py-1 br-10 border-p">
                                View
                              </button>
                            </Link>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Designation</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {recruiter.designation}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Location</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {location.map((citys, index) => (
                                <span key={index}>
                                  {recruiter.location == citys.id
                                    ? citys.location
                                    : ""}
                                </span>
                              ))}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>

                        <hr />
                        <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Organization</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {recruiter.organization}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        {/* <hr /> */}
                        {/* <MDBRow>
                          <MDBCol sm="5">
                            <MDBCardText>Recruiter Type</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="6">
                            <MDBCardText className="text-muted">
                              {recruiter.recruiter_type}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow> */}
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
              <MDBContainer className="px-5 mt-4">
                <MDBRow>
                  <MDBCol lg="12">
                    <MDBCard className="mb-2 ">
                      <MDBCardBody>
                        <MDBRow>
                          <MDBCol sm="2">
                            <MDBCardText>About</MDBCardText>
                          </MDBCol>
                          <MDBCol sm="10">
                            <MDBCardText className="text-muted">
                              {recruiter.about}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Viewrecruiter;
