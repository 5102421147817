import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import styled from "styled-components";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomeLoader from "../../components/CustomeLoader";

const Category = () => {
  const [categories, setcategories] = useState([]);
  const [show, setshow] = useState(0);
  const [categorydata, setCategorydata] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  // ------------------------------------------------------------------------------------------------

  // --------------------------------------------------UPDATE CATEGORY------------------------------------
  const handleUpdate = async (e) => {
    e.preventDefault();
    axios
      .put("/api/update_category/" + categoryid, {
        category: categorydata,
        headers: {
          Accept: "application/json",
        },
      })
      .then((result) => {
        console.log(result);
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // -------------------------------------------------ADD CATEGORY------------------------------------

  const handleAddIndustry = async (e) => {
    e.preventDefault();
    const categoryobj = new FormData();

    categoryobj.append("category", categorydata);
    axios
      .post("/api/create_category", categoryobj, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((result) => {
        console.log(result);
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // -------------------------------------------------FETCH ALL CATEGORY------------------------------------

  useEffect(() => {
    axios
      .get("/api/get_all_category")
      .then((res) => {
        setcategories(res.data.data);
        console.log(res);
      })
      .catch((err) => {});
  }, []);

  // -------------------------------------------------DELETE SINGLE CATEGORY------------------------------------

  const deleteHandler = (row) => {
    // alert("Are You Sure...... !");
    axios
      .delete("/api/delete_category/" + row.id, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        let newArr = categories.filter((items) => {
          return row.id !== items.id;
        });
        setcategories(newArr);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // -------------------------------------------------EDIT SINGLE CATEGORY ROW DATA------------------------------------

  const editHandler = (row) => {
    setshow(1);
    setCategorydata(row.category);
    setCategoryid(row.id);
  };
  const addhandle = () => {
    setshow(2);
  };

  // --------------------------------------------------------------------------------------------------------

  // ------------------------------------------------CATEGORY TABLE COLUMN---------------------------------------
  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      width: "70px",
    },

    {
      name: "Category",
      selector: (row) => <div>{row.category}</div>,
      sortable: true,
      width: "250px",
    },

    {
      name: "action",
      selector: (row) => (

   
    <div>
       { row.id == 12 || row.id == 13 || row.id == 21 || row.id == 23 || row.id == 15 ? "" : 
       <>
    <button
      onClick={() => editHandler(row)}
      className="bg-purple text-white border-p px-2 py-1 br-10 m-1"
    >
      <EditIcon />
    </button>
    <button
      className="bg-red text-white border-r px-2 py-1 br-10 m-1"
      onClick={() => {
        if (window.confirm("Are you sure you wish to delete this category?"))
          deleteHandler(row);
      }}
    >
      <DeleteIcon />
    </button>{" "} </>}
  </div> 
    
      ),
      width: "200px",
      sortable: false,
    },
  ];

  // --------------------------------------------------------------------------------------------------

  // ---------------------------------------------------NO LOGIN WITHOUT TOKEN--------------------------------

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  // -----------------------------------------------------------------------------------------------------------

  // ----------------------------------------------table data loading-----------------------------------------------

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(categories);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  // -----------------------------------------------------FORM INPUT FIELD CSS------------------------------------

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
      cursor: pointer;
    }
  `;

  // ----------------------------------------------------------------------------------------------------------

  // --------------------------------------------------------FILTER DATA FROM TABLE---------------------------------

  const filteredItems = categories.filter(
    (item) =>
      item.category &&
      item.category.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  // ----------------------------------------------------------------------------------------------------------------

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0 ">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <Navbar />

            <div className="container">
              <div className="row mt-2">
                <div className="col-sm-6 mt-1">
                  {show == 0 ? (
                    <>
                      {" "}
                      <TextField
                        type="text"
                        placeholder="Search...."
                        value={filterText}
                        autoFocus
                        onChange={(e) => setFilterText(e.target.value)}
                      />
                      <button
                        className="bg-purple text-white border-p m-1"
                        onClick={handleClear}
                      >
                        X
                      </button>{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {show == 1 ? (
                    <div>
                      <span>Edit Category :</span>{" "}
                      <input
                        type="text"
                        value={categorydata}
                        name="category"
                        onChange={(e) => setCategorydata(e.target.value)}
                      />{" "}
                      <button
                        className="bg-purple text-white border-p "
                        onClick={handleUpdate}
                      >
                        Update
                      </button>
                    </div>
                  ) : (
                    ""
                  )}

                  {show == 2 ? (
                    <div>
                      <span>Add Category :</span>{" "}
                      <input
                        type="text"
                        name="category"
                        onChange={(e) => setCategorydata(e.target.value)}
                      />{" "}
                      <button
                        className="bg-purple text-white border-p "
                        onClick={handleAddIndustry}
                      >
                        Submit
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-sm-6">
                  <div className="mt-2 text-right ">
                    <button
                      className="bg-purple text-white border-p px-3 py-1 br-10 m-1"
                      onClick={addhandle}
                    >
                      + Add Category
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="m-1">
              <DataTable
                columns={columns}
                data={filteredItems}
                defaultSortFieldId
                fixedHeaderScrollHeight="400px"
                fixedHeader
                responsive
                pagination={10}
                subHeaderAlign="right"
                highlightOnHover
                progressPending={pending}
                progressComponent={<CustomeLoader />}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Category;
