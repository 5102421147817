import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import axios from "axios";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";

const AddNotifications = () => {
  // ------------------------------------------------FETCH ALL LOCATIONS --------------------------------------------

  const [location, setLocationlist] = useState([]);
  useEffect(() => {
    axios.get("/api/locations").then((res) => {
      setLocationlist(res.data.data);
    });
  }, []);

  const companytype = [
    {
      value: "0",
      name: "Company",
    },
    {
      value: "1",
      name: "Consltant",
    },
  ];
  const [errormsg, seterrorMsg] = useState("");
  const [msg, setMsg] = useState("");

  const status =
    typeof window !== "undefined" ? localStorage.getItem("status") : null;
  const [notificationInfo, setnotificationInfo] = useState({
    title: "",
    description: "",
  });


  const handleChange = (e) => {
    e.persist();
    setnotificationInfo({ ...notificationInfo, [e.target.name]: e.target.value });
  };

  // ------------------------------------------------ADD NEW RECRUITER--------------------------------------------
console.log(notificationInfo.user_type)
  const AddNotificationhandler = async (e) => {
    e.preventDefault();
    const notificationobj = new FormData();
    notificationobj.append("title", notificationInfo.title);
    notificationobj.append("description", notificationInfo.description);
    notificationobj.append("user_type", notificationInfo.user_type);
   
  

    axios
      .post("/api/create_notification", notificationobj, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + status,
        },
      })
      .then((result) => {
        setMsg(result.data.message);
      })
      .catch((error) => {
        seterrorMsg(error.response.data.message);
      });
  };

  // -----------------------------------------------NO ACCESS WITHOUT TOKAN--------------------------------------------

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10 ">
          <div className="container-fluid">
            <Navbar />
            <div className="p-2 bg-white">
              <span>
                <Link to="/dashboard" className="text-d-none">
                  Dashboard
                </Link>{" "}
                /{" "}
                <Link to="/notification" className="text-d-none">
                  Notifications
                </Link>{" "}
                / Add
              </span>
            </div>
            <section
              className="bg-gre"
              style={{
                padding: "10px 0px 30px 0",
                minHeight: "570px",
              }}
            >
              {msg && <div className="alert alert-success">{msg}</div>}
              {errormsg && <div className="alert alert-danger">{errormsg}</div>}
              <MDBContainer
                className="px-5 mt-4"
                style={{ alignItems: "center" }}
              >
                <MDBRow>
                  <MDBCol lg="8" style={{ margin: "auto" }}>
                    <MDBCard className="mb-2 ">
                      <MDBCardBody>
                        <MDBRow>
                          <div className="container m-0">
                            <div className="row p-3 m-0">
                              <div className="col-12 px-3">
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">Title</span>
                                </div>

                                <div className="mt-2">
                                  <TextField
                                    name="title"
                                    id="outlined-basic"
                                    onChange={handleChange}
                                    required
                                    type="text"
                                    placeholder=""
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>

                             

                                <div className="mt-3">
                                  <span className="fw-600 fs-15">Description</span>
                                </div>
                                <div className="mt-2 ">
                                  <TextField
                                    id="outlined-basic"
                                    onChange={handleChange}
                                    type="text"
                                    required
                                    name="description"
                                    rows={5}
                                    multiline
                                    placeholder=""
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">User Type</span>
                                </div>
                                <div className="mt-2 mb-3">
                                 <select name="user_type" className="w-100 height-55" onChange={handleChange}>
                                 <option >Select</option>
                                    <option value="1">Seeker</option>
                                    <option value="2">Recruiter</option>
                                 </select>
                                </div>

                                <div className="row my-3">
                                  <center>
                                    {" "}
                                    <button
                                      className="px-5 py-2 text-white bg-purple border-p br-5 "
                                      onClick={AddNotificationhandler}
                                    >
                                      Submit{" "}
                                    </button>
                                  </center>
                                </div>
                              </div>
                              <div className="col-sm-2"></div>
                            </div>
                          </div>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNotifications;
