import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Link } from "react-router-dom";
import styled from "styled-components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomeLoader from "../../components/CustomeLoader";
import { downloadExcel } from "react-export-table-to-excel";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const Seeker = () => {
  const [seeker, setSeeker] = useState([]);
  const [allseeker, setAllSeeker] = useState([]);
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [errormsg, seterrorMsg] = useState("");
  const [msg, setMsg] = useState("");
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  // ------------------------------------FETCH ALL SEEKER---------------------------------------
  const header = [
    "ID",
    "Email",
    "Seeker Name",
    "Gender",
    "Date Of Birth",
    "Current Location",
    "Mobile",
    "Notice Period",
    "Annual Salary (in lac)",
    "Expected Salary (in lac)",
    "Resume",
    "Cover Latter",
    "Industry",
    "Functional Area",
    "Sign Up Date ",
    "Total Experience",
    "Designation",
    "Current Company",
    "Preferred Location",
  ];

 
  useEffect(() => {
    axios
      .get("/api/get_all_seekers_new")
      .then((res) => {
        console.log(res, "result  new");
        setAllSeeker(res?.data?.data);
      })

      .catch((err) => {
        console.log(err);
      });
  }, []);
  
  useEffect(() => {
    axios
      .get("/api/get_all_seekers")
      .then((res) => {
        // console.log(res, "result");
        setSeeker(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  function handleDownloadExcel() {
    downloadExcel({
      fileName: "All Seeker",
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header,
        // accept two different data structures
        body: filteredItems,
      },
    });
  }

  // ------------------------------------------------------------------------------------------------

  // --------------------------------------------------DELETE SEEKER------------------------------------

  const deleteHandler = (row) => {
    axios
      .delete("/api/delete_seeker/" + row?.id, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        setMsg(result.data.message);
        let newArr = allseeker.filter((items) => {
          return row?.id !== items?.id;
        });
        setAllSeeker(newArr);
        //window.location.reload(false);
      })
      .catch((error) => {
        seterrorMsg(error.response.data.message);
      });
  };

  // const ExpandedComponent = ({ data }) => (
  //   <pre>
  //     <section className="container" style={{ backgroundColor: "#eee" }}>
  //       <MDBContainer className="p-3">
  //         <MDBRow >
  //           <MDBCol lg="5">
  //             <MDBCard>
  //               <MDBCardBody>
  //                 <MDBCardBody>
  //                   <MDBRow>
  //                     <MDBCol sm="5">
  //                       <MDBCardText>Current Designation</MDBCardText>
  //                     </MDBCol>
  //                     <MDBCol sm="7">
  //                       <MDBCardText className="text-muted">
  //                         {data.designation == null ? "NA" : data.designation }
  //                       </MDBCardText>
  //                     </MDBCol>
  //                   </MDBRow>
  //                   <hr />
  //                   <MDBRow>
  //                     <MDBCol sm="5">
  //                       <MDBCardText>Current Company</MDBCardText>
  //                     </MDBCol>
  //                     <MDBCol sm="7">
  //                       <MDBCardText className="text-muted">
  //                       {data.organization == null ? "NA" : data.organization } 
  //                       </MDBCardText>
  //                     </MDBCol>
  //                   </MDBRow>
  //                   <hr />
  //                   <MDBRow>
  //                     <MDBCol sm="5">
  //                       <MDBCardText>Current CTC</MDBCardText>
  //                     </MDBCol>
  //                     <MDBCol sm="7">
  //                       <MDBCardText className="text-muted">
  //                         {data.annual_salary  == " LPA" ? "NA" :  data.annual_salary}
  //                       </MDBCardText>
  //                     </MDBCol>
  //                   </MDBRow>
  //                   <hr />
  //                   <MDBRow>
  //                     <MDBCol sm="5">
  //                       <MDBCardText>Expected CTC</MDBCardText>
  //                     </MDBCol>
  //                     <MDBCol sm="7">
  //                       <MDBCardText className="text-muted">
  //                       {data.expected_salary  == " LPA" ? "NA" :  data.expected_salary}
                       
  //                       </MDBCardText>
  //                     </MDBCol>
  //                   </MDBRow>
  //                 </MDBCardBody>
  //               </MDBCardBody>
  //             </MDBCard>
  //           </MDBCol>
  //           <MDBCol lg="7">
  //             <MDBCard>
  //               <MDBCardBody>
  //                 <MDBCardBody>
  //                   <MDBRow>
  //                     <MDBCol sm="3">
  //                       <MDBCardText>Notice Period</MDBCardText>
  //                     </MDBCol>
  //                     <MDBCol sm="9">
  //                       <MDBCardText className="text-muted">
  //                         {data.notice_period == null ? "NA" : data.notice_period}
  //                       </MDBCardText>
  //                     </MDBCol>
  //                   </MDBRow>
  //                   <hr />
  //                   <MDBRow>
  //                     <MDBCol sm="3">
  //                       <MDBCardText>Location</MDBCardText>
  //                     </MDBCol>
  //                     <MDBCol sm="9">
  //                       <MDBCardText className="text-muted">
  //                         {data.current_location == null ? "NA" : data.current_location }
  //                       </MDBCardText>
  //                     </MDBCol>
  //                   </MDBRow>
  //                   <hr />
  //                   <MDBRow>
  //                     <MDBCol sm="3">
  //                       <MDBCardText>Preferred Location</MDBCardText>
  //                     </MDBCol>
  //                     <MDBCol sm="9">
  //                       <MDBCardText className="text-muted" style={{wordBreak:"break-all"}}>
  //                         {data.preferred_location == "" ? "NA" : data.preferred_location}
  //                       </MDBCardText>
  //                     </MDBCol>
  //                   </MDBRow>
  //                 </MDBCardBody>
  //               </MDBCardBody>
  //             </MDBCard>
  //           </MDBCol>
  //         </MDBRow>
  //       </MDBContainer>
  //     </section>
  //   </pre>
  // );

  // --------------------------------------------------------------------------------------------------------

  // -----------------------------------------------SEEKER TABLE COLUMN---------------------------------------
  const columns = [
    {
      name: "View",
      selector: (row) => (
        <Link to={`/seeker/view/${row?.id}`}>
          <button className="bg-purple text-white border-p px-2 py-1 br-10 m-1">
            <VisibilityIcon />
          </button>
        </Link>
      ),

      width: "90px",
    },
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
    },
   

    {
      name: "Name",
      selector: (row) => <span style={{textTransform:"capitalize"}}>{row?.name}</span>,
      sortable: true,
      width: "200px",
    },
    {
      name: "Current Company",
      selector: (row) =>  <span>{row?.organization == null ?"NA" : row?.organization}</span>,
      sortable: true,
      width: "250px",
    },
    {
      name: "Current CTC",
      selector: (row) => <span>{row?.annual_salary == " LPA" ?"NA" : row?.annual_salary}</span>,
      sortable: true,
      width: "130px",
    },
    {
      name: "Expected CTC",
      selector: (row) =><span>{row?.expected_salary == " LPA" ?"NA" : row?.expected_salary}</span> ,
      sortable: true,
      width: "130px",
    },
    {
      name: "Notice Period",
      selector: (row) =><span>{row?.notice_period == null ?"NA" : row?.notice_period}</span>,
      sortable: true,
      width: "140px",
    },
    {
      name: "Signup Date",
      selector: (row) =><span>{row?.created_at}</span>,
      sortable: true,
      width: "170px",
    },
    {
      name: "Preferred Location",
      selector: (row) => <span >{row?.preferred_location == "" ?"NA" : row?.preferred_location}</span>,
      sortable: true,
      width: "auto",
    },

    {
      name: "action",
      selector: (row) => (
        <div>
          <button
            className="bg-red text-white border-r px-2 py-1 br-10 m-1"
            onClick={() => {
              if (window.confirm("Are you sure you wish to delete this seeker?"))
                deleteHandler(row);
            }}
          >
            <DeleteIcon />
          </button>{" "}
        </div>
      ),
      width: "100px",
      sortable: false,
    },
  ];

  // --------------------------------------------------------------------------------------------------

  // ---------------------------------------------------NO LOGIN WITHOUT TOKEN--------------------------------

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  // -----------------------------------------------------------------------------------------------------------

  // ----------------------------------------------table data loading-----------------------------------------------

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(allseeker);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  // -----------------------------------------------------FORM INPUT FIELD CSS------------------------------------

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    &:hover {
      cursor: pointer;
    }
  `;

  // ----------------------------------------------------------------------------------------------------------

  // --------------------------------------------------------FILTER DATA FROM TABLE---------------------------------

  const filteredItems = allseeker.filter(
    (item) =>
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.phone &&
        item.phone.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.email &&
        item.email.toLowerCase().includes(filterText.toLowerCase()))
  );
  
  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };
  // ----------------------------------------------------------------------------------------------------------------
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0 ">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <Navbar />

            <div className="container">
              <div className="row mt-2">
                {msg && <div className="alert alert-success">{msg}</div>}
                {errormsg && (
                  <div className="alert alert-danger">{errormsg}</div>
                )}
                <div className="col-sm-10 mt-1">
                  <TextField
                    type="text"
                    placeholder="Search...."
                    value={filterText}
                    autoFocus
                    onChange={(e) => setFilterText(e.target.value)}
                  />
                  <button
                    className="bg-purple text-white border-p m-1"
                    onClick={handleClear}
                  >
                    X
                  </button>
                </div>
                <div className="col-sm-2">
                  <button
                    className="bg-purple text-white border-p px-3 py-1 br-5"
                    onClick={handleDownloadExcel}
                  >
                    <FileDownloadIcon />
                    CSV
                  </button>
                  {/* <div className="mt-1 text-right ">
                        <Link
                          to="/seeker/add"
                        >
                             <button
                        className="bg-purple text-white border-p px-2 py-1 br-10 m-1"
                      >
                          + Add Seeker</button>
                        </Link>
                      </div> */}
                </div>
              </div>
            </div>

            <div className="m-1">
              <DataTable
                columns={columns}
                data={filteredItems}
                defaultSortFieldId
                fixedHeaderScrollHeight="480px"
                fixedHeader
                responsive
                // expandableRows
                // expandableRowsComponent={ExpandedComponent}
                pagination={10}
                subHeaderAlign="right"
                highlightOnHover
                progressPending={pending}
                progressComponent={<CustomeLoader />}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Seeker;
