import "./navbar.scss";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="nav-head">
          <strong>Analytics Jobs</strong>
        </div>

        <div className="items">
          <div className="item">
            <button
              className="bg-white text-purple border-p br-10 px-2 py-1"
              onClick={() => {
                localStorage.removeItem("status");
                alert("Are you sure to logout !");
                navigate("/");
              }}
            >
              {" "}
              <ExitToAppIcon className="icon" />
              &nbsp;Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
