import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Link } from "react-router-dom";
import styled from "styled-components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomeLoader from "../../components/CustomeLoader";
import defaultimage from "./../all-image/default-image.png";
import { downloadExcel } from "react-export-table-to-excel";
import FileDownloadIcon from '@mui/icons-material/FileDownload';


const Recruiter = () => {
  const [recruiter, setRecruiter] = useState([]);
  const [rows, setRows] = useState([]);
  const [errormsg, seterrorMsg] = useState("");
  const [msg, setMsg] = useState("");
  const [filterText, setFilterText] = useState("");
  const [pending, setPending] = useState(true);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  // --------------------------------------------------FETCH ALL RECRUITER-------------------------------------------------

  const header = ["ID", "Company Logo", "Profile Image" ,"Name","Location","Phone","Work Email Id","Company Name","Designation","Website","About","Linkedin","Twitter","Facebook","Email Verified"];





  function handleDownloadExcel() {
    downloadExcel({
      fileName: "All Recruiter",
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header,
        // accept two different data structures
        body: filteredItems,
      },
    });
  }
  useEffect(() => {
    axios
      .get("/api/get_all_recruiter")
      .then((res) => {
        setRecruiter(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => {});
  }, []);

  // -------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------DELETE RECRUITER-------------------------------------------------

  const deleteHandler = (row) => {
    axios
      .delete("/api/delete_recruiter/" + row.id, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        setMsg(result.data.message)
        let newArr = recruiter.filter((items) => {
          return row.id !== items.id;
        });
        setRecruiter(newArr);
        //window.location.reload(false);
      })
      .catch((error) => {
        seterrorMsg(error.response.data.message);
        console.log(error);
      });
  };

  // ---------------------------------------------------------------------------------------------------------------------

  // --------------------------------------------RECRUITER TABLeCOLUMN-----------------------------------------------
  const columns = [
    {
      name: "View",
      selector: (row) => (
        <Link to={`/recruiters/view/${row.id}`}>
           <button className="bg-purple text-white border-p px-2 py-1 br-10 m-1">
            <VisibilityIcon />
          </button>
        </Link>
      ),

      width: "90px",
    },
    {
      name: "#",
      selector: (row,index) => index + 1,
          width: "70px",
    },
//     {
//       name: "Image",
//       selector: (row) => (
// row.company_logo ==null ?   <img
//   width={40}
//   style={{ borderRadius: "50%" }}
//   className="m-1"
//   src={defaultimage}
//   alt="MDN logo"
// /> :   <img
//           width={40}
//           style={{ borderRadius: "50%" }}
//           className="m-1"
//           src={row.company_logo}
//           alt="MDN logo"
//         /> 
    
//       ),
//       width: "100px",
//     },
    {
      name: "name",
      selector: (row) => <span style={{textTransform:"capitalize"}}>{row.name}</span>,
      sortable: true,
      width: "190px",
    },

    {
      name: "Company Name",
      selector: (row) => row.organization,
      sortable: true,
      width: "250px",
    },
    {
      name: "Mobile",
      selector: (row) => row.phone,
      sortable: true,
      width: "150px",
    },
    {
      name: "Email",
      selector: (row) => row.work_email_id,
      sortable: true,
      width: "250px",
    },

    {
      name: "action",
      selector: (row) => (
        <div>
          <Link to={`/recruiters/edit/${row.id}`} style={{ width: "50px" }}>
            <button className="bg-purple text-white border-p px-2 py-1 br-10 m-1">
              <EditIcon />
            </button>
          </Link>
          <button
            className="bg-red text-white border-r px-2 py-1 br-10 m-1"
            onClick={() => { if (window.confirm('Are you sure you wish to delete this recruiter?')) deleteHandler(row) }}
          >
            <DeleteIcon />
          </button>{" "}
        </div>
      ),
      width: "140px",
      sortable: false,
    },
  ];

  // ---------------------------------------------------------------------------------------------------------------------

  // ---------------------------------------------------NO LOGIN WITHOUT TOKEN--------------------------------------------

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  // --------------------------------------------------------------------------------------------------------------------

  // ----------------------------------------------table data loading----------------------------------------------------

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(recruiter);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  
  // -----------------------------------------------------FORM INPUT FIELD CSS--------------------------------------------

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    &:hover {
      cursor: pointer;
    }
  `;
  // -----------------------------------------------------------------------------------------------------------------

  // --------------------------------------------------------FILTER DATA FROM TABLE---------------------------------

  const filteredItems = recruiter.filter(
    (item) =>
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.company_name &&
        item.company_name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.work_email_id &&
        item.work_email_id.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.phone &&
        item.phone.toLowerCase().includes(filterText.toLowerCase()))
  );

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  // ----------------------------------------------------------------------------------------------------------------

  return (
    <>
      <div className="container-fluid">
       
        <div className="row">
          <div className="col-sm-2 p-0 ">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <Navbar />

            <div className="container">
          
              <div className="row mt-2">
              {msg && <div className="alert alert-success">{msg}</div>}
                   {errormsg && <div className="alert alert-danger">{errormsg}</div>}
                <div className="col-sm-8 mt-2">
                  <TextField
                    type="text"
                    placeholder="Search...."
                    value={filterText}
                    autoFocus
                    onChange={(e) => setFilterText(e.target.value)}
                  />
                  <button
                    className="bg-purple text-white border-p m-1"
                    onClick={handleClear}
                  >
                    X
                  </button>
                </div>
                <div className="col-sm-2"><div className="mt-2 text-right"><button className="bg-purple m-1 text-white border-p  py-1 br-5" onClick={handleDownloadExcel}><FileDownloadIcon/>CSV</button></div></div> 
                <div className="col-sm-2">
                  <div className="mt-2  ">
                    <Link to="/add-recruiter">
                      <button className="bg-purple text-white border-p px-3 py-1 br-5 m-1">
                        + Add Recruiter
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="m-1">
              <DataTable
                columns={columns}
                data={filteredItems}
                defaultSortFieldId
                fixedHeaderScrollHeight="480px"
                fixedHeader
                responsive
                pagination={10}
                subHeaderAlign="right"
                highlightOnHover
                progressPending={pending}
                progressComponent={<CustomeLoader />}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Recruiter;
