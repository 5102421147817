import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Link } from "react-router-dom";
import styled from "styled-components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomeLoader from "../../components/CustomeLoader";

const Subscription = () => {
  const [subsription, setsubsription] = useState([]);
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [errormsg, seterrorMsg] = useState("");
  const [msg, setMsg] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  // ------------------------------------FETCH ALL SUBSCRIPTION---------------------------------------

  useEffect(() => {
    axios
      .get("/api/get_subscription")
      .then((res) => {
        setsubsription(res.data.data);
      })

      .catch((err) => {
        console.log(err)
      });
  }, []);

  // ------------------------------------------------------------------------------------------------

  // --------------------------------------------------DELETE subscription------------------------------------

  const deleteHandler = (row) => {
    axios
      .delete("/api/delete_subscription/" + row.id, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        setMsg(result.data.message);
        let newArr = subsription.filter((items) => {
          return row.id !== items.id;
        });
        setsubsription(newArr);
        //window.location.reload(false);
      })
      .catch((error) => {
        seterrorMsg(error.response.data.message);
        console.log(error);
      });
  };

  // --------------------------------------------------------------------------------------------------------

  // ------------------------------------------------SUBSCRIPTION TABLE COLUMN---------------------------------------
  const columns = [
    {
      name: "View",
      selector: (row) => (
        <Link to={`/subscription/view/${row.id}`}>
          <button className="bg-purple text-white border-p px-2 py-1 br-10 m-1">
            <VisibilityIcon />
          </button>
        </Link>
      ),

      width: "90px",
    },

    {
      name: "#",
      selector: (row,index) => index + 1,
      width: "70px",
    },
    {
      name: "Image",
      selector: (row) => (
        <Link target="_blank" to={row.image}><img
          width={50}
          className="m-1"
          src={row.image}
          alt="MDN logo"
        /></Link>
      ),
      width: "100px",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      width: "220px",
    },

    {
      name: "Price",
      selector: (row) => "₹" + row.price,
      sortable: true,
      width: "150px",
    },
    {
        name: "Discription",
        selector: (row) => row.description,
        sortable: true,
        width: "350px",
      },
   
    {
      name: "action",
      selector: (row) => (
        <div>
          <Link to={`/subscription/edit/${row.id}`} style={{ width: "50px" }}>
            <button className="bg-purple text-white border-p px-2 py-1 br-10 m-1">
              <EditIcon />
            </button>
          </Link>
          <button
            className="bg-red text-white border-r px-2 py-1 br-10 m-1"
            onClick={() => { if (window.confirm('Are you sure you wish to delete this subscription?')) deleteHandler(row) }}
          >
            <DeleteIcon />
          </button>{" "}
        </div>
      ),
      width: "150px",
      sortable: false,
    },
  ];

  // --------------------------------------------------------------------------------------------------

  // ---------------------------------------------------NO LOGIN WITHOUT TOKEN--------------------------------

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  // -----------------------------------------------------------------------------------------------------------

  // ----------------------------------------------table data loading-----------------------------------------------

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(subsription);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  // -----------------------------------------------------FORM INPUT FIELD CSS------------------------------------

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
      cursor: pointer;
    }
  `;

  // ----------------------------------------------------------------------------------------------------------

  // --------------------------------------------------------FILTER DATA FROM TABLE---------------------------------

  const filteredItems = subsription.filter(
    (item) =>
      (item.title &&
        item.title.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.price &&
        item.price.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.description &&
        item.description.toLowerCase().includes(filterText.toLowerCase())) 
  );

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  // ----------------------------------------------------------------------------------------------------------------

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0 ">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <Navbar />

            <div className="container">
              <div className="row mt-2">
              {msg && <div className="alert alert-success">{msg}</div>}
              {errormsg && <div className="alert alert-danger">{errormsg}</div>}
                <div className="col-sm-6 mt-1">
                  <TextField
                    type="text"
                    placeholder="Search...."
                    value={filterText}
                    autoFocus
                    onChange={(e) => setFilterText(e.target.value)}
                  />
                  <button
                    className="bg-purple text-white border-p m-1"
                    onClick={handleClear}
                  >
                    X
                  </button>
                </div>
                <div className="col-sm-6">
                  <div className="mt-2 text-right ">
                    <Link to="/add-subscription">
                      <button className="bg-purple text-white border-p px-3 py-1 br-10 m-1">
                        + Add New
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="m-1">
              <DataTable
                columns={columns}
                data={filteredItems}
                defaultSortFieldId
                fixedHeaderScrollHeight="480px"
                fixedHeader
                responsive
                pagination={10}
                subHeaderAlign="right"
                highlightOnHover
                progressPending={pending}
                progressComponent={<CustomeLoader />}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Subscription;

