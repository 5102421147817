import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import axios from "axios";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const RecruiterReport = () => {
  const tokan =
    typeof window !== "undefined" ? localStorage.getItem("status") : null;
  const [industry_id, setindustry] = useState(1);
  const [industrylist, setIndustrylist] = useState([]);

  // ----------------------------------------------------FETCH ALL INDUSTRY---------------------------------------------

  useEffect(() => {
    axios.get("/api/industry").then((res) => {
      setIndustrylist(res.data.data);
    });
  }, []);

  // ----------------------------------------------------FETCH POSTWISE REPORT DATA----------------------------------------------

  const [Applied, setApplied] = useState();
  const [totalpost, settotalpost] = useState();
  const [Shortlisted, setShortlisted] = useState();
  const [monthname, setmonthname] = useState();


 
 useEffect(() => {
    axios
      .get("/api/industry_reports/" + industry_id, {
      headers: {
        Accept: "application/json",
      },
    }
      )
      .then((res) => {
        setmonthname(res.data.month)
        settotalpost(res.data.total_post)
        setApplied(res.data.total_applied)
        setShortlisted(res.data.total_shortlist)
      });
  }, [industry_id]);
 

  // ----------------------------------------------------CHART OPTION----------------------------------------------





  const option = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Industry-Wise-Report",
      },
    },
  };

  
  // ----------------------------------------------------CHART DATA----------------------------------------------

  const data = {
    labels: monthname,

    datasets: [
      {
        label: "Total Jobs",
        data: totalpost,
        backgroundColor: "#5d3891",
      },
      {
        label: "Applied",
        data: Applied,
        backgroundColor: "#850cec",
      },
      {
        label: "Shortlisted",
        data: Shortlisted,
        backgroundColor: "#C8a5e6",
      },
    ],
  };

  // -----------------------------------------------------NO ACCESS WITHOUT TOKAN----------------------------------------------

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10 ">
          <div className="container-fluid">
            <Navbar />
            <div className="p-1 bg-white">
              <span>
                <Link to="/dashboard" className="text-d-none">
                  Dashboard
                </Link>{" "}
                / Recruiters-report
              </span>
            </div>
            <section className="bg-gre p-2">
              <select
                className="ml-5 mt-2 h-3 border-c"
                required
                onChange={(e) => setindustry(e.target.value)}
             
              >
                {industrylist.map((items, index) => (
                  <option key={index} value={items.id}>
                    &nbsp;{items.industry}&nbsp;
                  </option>
                ))}
              </select>

              <div className="App">
                <Bar options={option} data={data} />
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruiterReport;
