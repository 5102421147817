
import "./chart.scss";
import {
  ResponsiveContainer,
  BarChart, Bar , XAxis , YAxis , Tooltip , CartesianGrid
} from "recharts";

const data = [
  { name: "January", Total: 1200 },
  { name: "February", Total: 2100 },
  { name: "March", Total: 800 },
  { name: "April", Total: 1600 },
  { name: "May", Total: 900 },
  { name: "June", Total: 1700 },
];

const Chart = ({aspect}) => {


  return (
    <div className="chart">
      <div className="title">Sales Report</div>
      <ResponsiveContainer aspect={aspect}>
        <BarChart  data={data}    width={730} height={250}>
        <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis  />
          <Tooltip/>
          
            < Bar dataKey="Total" fill="#5d3891"   />
        </BarChart>
     
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
