import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Link } from "react-router-dom";
import styled from "styled-components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from '@mui/material/Tooltip';
import CustomeLoader from "../../components/CustomeLoader";

const AllJobs = () => {
  const [jobs, setjobs] = useState([]);

  const [rows, setRows] = useState([]);
  const [errormsg, seterrorMsg] = useState("");
  const [msg, setMsg] = useState("");
  const [filterText, setFilterText] = useState("");
  const [pending, setPending] = useState(true);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  // --------------------------------------------------FETCH ALL RECRUITER-------------------------------------------------

  useEffect(() => {
    axios
      .get("/api/get_all_jobs")
      .then((res) => {
        setjobs(res.data.data);
        console.log(res.data.data);
      })

      .catch((err) => {
        console.log(err);
      });
  }, []);

  // ---------------------------------------------------------------------------------------------------------------------

  // --------------------------------------------------DELETE RECRUITER---------------------------------------------------

  const deleteHandler = (row) => {
    axios
      .delete("/api/jobs/" + row.id, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        setMsg(result.data.message);
        let newArr = jobs.filter((items) => {
          return row.id !== items.id;
        });
        setjobs(newArr);
        //window.location.reload(false);
      })
      .catch((error) => {
        seterrorMsg(error.response.data.message);
        console.log(error);
      });
  };

  // ---------------------------------------------------------------------------------------------------------------------

  // ------------------------------------------------RECRUITER TABLE COLUMN-----------------------------------------------
  const columns = [
    {
      name: "View",
      selector: (row) => (
        <Link to={`/jobs/view/${row.id}`}>
           <button className="bg-purple text-white border-p px-2 py-1 br-10 m-1">
            <VisibilityIcon />
          </button>
        </Link>
      ),

      width: "90px",
    },
    {
      name: "#",
      selector: (row,index) => index + 1,
          width: "70px",
    },

    // {
    //   name: "ID",
    //   selector: (row) => <div>{row.id}</div>,
    //   sortable: true,
    //   width: "70px",
    // },
   
    {
      name: "Title",
      selector: (row) => row.job_title,
      sortable: true,
      width: "250px",
    },

    {
      name: "Company Name",
      // eslint-disable-next-line no-unused-expressions
      selector: (row) => row.recruiter?.organization  ,
      sortable: true, 
      width: "220px",
    },
    {
      name: "Experience",
      selector: (row) => <span>{row.years_of_experience_min + "-" + row.years_of_experience_max } years</span>,
      sortable: true,
      width: "150px",
    },
    {
      name: "Salary",
      selector: (row) => <span>{row.annual_salary_min
        + "-" + row.annual_salary_max      } Lac</span>,
      sortable: true,
      width: "150px",
    },
    // {
    //   name: "Description",
    //   selector: (row) => row.job_description,
    //   sortable: true,
    //   width: "250px",
    // },

    {
        name: "action",
        selector: (row) => (
          <div>
            <button
              className="bg-red text-white border-r px-2 py-1 br-10 m-1"
              onClick={() => { if (window.confirm('Are you sure you wish to delete this job?')) deleteHandler(row) }}
            >
              <DeleteIcon />
            </button>{" "}
          </div>
        ),}
  ];

  // ---------------------------------------------------------------------------------------------------------------------

  // ---------------------------------------------------NO LOGIN WITHOUT TOKEN--------------------------------------------

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  // --------------------------------------------------------------------------------------------------------------------

  // ----------------------------------------------table data loading----------------------------------------------------

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(jobs);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  
  // -----------------------------------------------------FORM INPUT FIELD CSS--------------------------------------------

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    &:hover {
      cursor: pointer;
    }
  `;
  // -----------------------------------------------------------------------------------------------------------------

  // --------------------------------------------------------FILTER DATA FROM TABLE---------------------------------

  const filteredItems = jobs.filter(
    (item) =>
      (item.job_title &&
        item.job_title.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.job_description &&
        item.job_description.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.location?.location &&
        item.location?.location.toLowerCase().includes(filterText.toLowerCase())) 
     
  );

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  // ----------------------------------------------------------------------------------------------------------------

  return (
    <>
      <div className="container-fluid">
       
        <div className="row">
          <div className="col-sm-2 p-0 ">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <Navbar />

            <div className="container">
          
              <div className="row mt-2">
              {msg && <div className="alert alert-success">{msg}</div>}
                   {errormsg && <div className="alert alert-danger">{errormsg}</div>}
                <div className="col-sm-6 mt-2">
                  <TextField
                    type="text"
                    placeholder="Search...."
                    value={filterText}
                    autoFocus
                    onChange={(e) => setFilterText(e.target.value)}
                  />
                  <button
                    className="bg-purple text-white border-p m-1"
                    onClick={handleClear}
                  >
                    X
                  </button>
                </div>
                <div className="col-sm-6">
                
                </div>
              </div>
            </div>

            <div className="m-1">
              <DataTable
                columns={columns}
                data={filteredItems}
                defaultSortFieldId
                fixedHeaderScrollHeight="480px"
                fixedHeader
                responsive
                pagination={10}
                subHeaderAlign="right"
                highlightOnHover
                progressPending={pending}
                progressComponent={<CustomeLoader />}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AllJobs;
