import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import axios from "axios";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
} from "mdb-react-ui-kit";

const AddRecruiter = () => {


// ------------------------------------------------FETCH ALL LOCATIONS --------------------------------------------

  const [location, setLocationlist] = useState([]);
  useEffect(() => {
    axios.get("/api/locations").then((res) => {
      setLocationlist(res.data.data);
    });
  }, []);

  const companytype = [
    {
      value: "0",
      name: "Company",
    },
    {
      value: "1",
      name: "Consltant",
    },
  ];
  const [errormsg, seterrorMsg] = useState("");
  const [msg, setMsg] = useState("");

  const status =
    typeof window !== "undefined" ? localStorage.getItem("status") : null;
  const [recruiterInfo, setRecruiterInfo] = useState({
    password: "",
    name: "",
    company_name: "",
    work_email_id: "",
    choose_your_company_type: "",
    phone: "",
    organization: "",
    designation: "",
    website: "",
    location: "",
    linkedin: "",
    twitter: "",
    facebook: "",
    about: "",
  });
  const [profileImg, setProfileImg] = useState({
    image: "",
  });
  const [companyImg, setCompanyImg] = useState({
    image: "",
  });
  const handleImage1 = (e) => {
    setProfileImg({ image: e.target.files[0] });
  };
  const handleImage2 = (e) => {
    setCompanyImg({ image: e.target.files[0] });
  };



  const handleChange = (e) => {
    e.persist();
    setRecruiterInfo({ ...recruiterInfo, [e.target.name]: e.target.value });
  };

 // ------------------------------------------------ADD NEW RECRUITER--------------------------------------------


  const AddcruiterInfos = async (e) => {
    e.preventDefault();
    const recruiterobj = new FormData();
    recruiterobj.append("name", recruiterInfo.name);
    recruiterobj.append("facebook", recruiterInfo.facebook);
    recruiterobj.append("twitter", recruiterInfo.twitter);
    recruiterobj.append("linkedin", recruiterInfo.linkedin);
    recruiterobj.append("company_name", recruiterInfo.company_name);
    recruiterobj.append(
      "choose_your_company_type",
      recruiterInfo.choose_your_company_type
    );
    recruiterobj.append("work_email_id", recruiterInfo.work_email_id);
    recruiterobj.append("organization", recruiterInfo.organization);
    recruiterobj.append("phone", recruiterInfo.phone);
    recruiterobj.append(
      "password_confirmation",
      recruiterInfo.password_confirmation
    );
    recruiterobj.append("designation", recruiterInfo.designation);
    recruiterobj.append("website", recruiterInfo.website);
    recruiterobj.append("location", recruiterInfo.location);
    recruiterobj.append("about", recruiterInfo.about);
    recruiterobj.append("profile_picture", profileImg.image);
    recruiterobj.append("company_logo", companyImg.image);
    recruiterobj.append("password", recruiterInfo.password);
    recruiterobj.append("recruiter_type", recruiterInfo.recruiter_type);
    recruiterobj.append("candidate_application",0 );
    recruiterobj.append("comments_on_shared_resume", 0);

    axios
      .post("/api/create_recruiter", recruiterobj, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + status,
        },
      })
      .then((result) => {
        setMsg(result.data.message);
      })
      .catch((error) => {
        seterrorMsg(error.response.data.message);
       
      });
  };

 // -----------------------------------------------NO ACCESS WITHOUT TOKAN--------------------------------------------


  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10 ">
          <div className="container-fluid">
            <Navbar />
            <div className="p-2 bg-white">
              <span>
                <Link to="/dashboard" className="text-d-none">
                  Dashboard
                </Link>{" "}
                /{" "}
                <Link to="/recruiters" className="text-d-none">
                  Recruiters
                </Link>{" "}
                / Add
              </span>
            </div>
            <section
              className="bg-gre"
              style={{
                padding: "10px 0px 30px 0",
                minHeight: "570px",
              }}
            >
              {msg && <div className="alert alert-success">{msg}</div>}
              {errormsg && <div className="alert alert-danger">{errormsg}</div>}
              <MDBContainer
                className="px-5 mt-4"
                style={{ alignItems: "center" }}
              >
                <MDBRow>
                  <MDBCol lg="8" style={{ margin: "auto" }}>
                    <MDBCard className="mb-2 ">
                      <MDBCardBody>
                        <MDBRow>
                          <div className="container m-0">
                            <div className="row p-3 m-0">
                              <div className="col-12 px-3">
                                <MDBRow>
                                  <MDBCol sm="5">
                                    <MDBCardText>Company Logo</MDBCardText>
                                  </MDBCol>
                                  <MDBCol sm="6">
                                    <MDBCardText className="text-muted">
                                      <input
                                        id="company"
                                        onChange={handleImage2}
                                        accept="image/*"
                                        required
                                        name="company_logo"
                                        type="file"
                                      />
                                    </MDBCardText>
                                  </MDBCol>
                                </MDBRow>

                                <MDBRow className="mt-5 mb-5">
                                  <MDBCol sm="5">
                                    <MDBCardText>Profile</MDBCardText>
                                  </MDBCol>
                                  <MDBCol sm="6">
                                    <MDBCardText className="text-muted">
                                      <input
                                        id="profile"
                                        accept="image/*"
                                        onChange={handleImage1}
                                        required
                                        name="profile_picture"
                                        type="file"
                                      />
                                    </MDBCardText>
                                  </MDBCol>
                                </MDBRow>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">
                                    Company Type
                                  </span>
                                </div>
                                <div className="mt-2">
                                  <select
                                    className="
                w-100 height-55 border-c"
                                    required
                                    name="choose_your_company_type"
                                    onChange={handleChange}
                                  >
                                    <option>select</option>
                                    {companytype.map((items, index) => (
                                      <option key={index} value={items.value}>
                                        {items.name}
                                      </option>
                                    ))}
                                  </select>{" "}
                                </div>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">
                                    Recruiter Type
                                  </span>
                                </div>
                                <div className="mt-2">
                                  <select
                                    className="
                w-100 height-55 border-c"
                                    required
                                    name="recruiter_type"
                                    onChange={handleChange}
                                  >
                                    <option>select</option>
                                    {companytype.map((items, index) => (
                                      <option key={index} value={items.value}>
                                        {items.name}
                                      </option>
                                    ))}
                                  </select>{" "}
                                </div>

                                <div className="mt-3">
                                  <span className="fw-600 fs-15">
                                    Company Name
                                  </span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    type="text"
                                    name="company_name"
                                    required
                                    onChange={handleChange}
                                    placeholder="enter company name"
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">Name</span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    type="text"
                                    name="name"
                                    required
                                    onChange={handleChange}
                                    placeholder="enter name"
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">
                                    Phone Number
                                  </span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    type="text"
                                    pattern="[6789][0-9]{9}"
                                    name="phone"
                                    required
                                    onChange={handleChange}
                                    placeholder="000-0000-000"
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">Email Id</span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    type="text"
                                    name="work_email_id"
                                    required
                                    onChange={handleChange}
                                    placeholder="enter email id"
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">Location</span>
                                </div>
                                <div className="mt-2">
                                  <select
                                    className="
                w-100 height-55 border-c"
                                    name="location"
                                    required
                                    onChange={handleChange}
                                  >
                                    <option>Select</option>
                                    {location.map((citys, index) => (
                                      <option key={index} value={citys.id}>
                                        {citys.location}
                                      </option>
                                    ))}
                                  </select>{" "}
                                </div>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">
                                    Designation
                                  </span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    onChange={handleChange}
                                    type="text"
                                    name="designation"
                                    required
                                    placeholder=""
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">Password</span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    name="password"
                                    id="outlined-basic"
                                    onChange={handleChange}
                                    required
                                    type="text"
                                    placeholder=""
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">
                                    Confirm Password
                                  </span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    name="password_confirmation"
                                    id="outlined-basic"
                                    onChange={handleChange}
                                    required
                                    type="text"
                                    placeholder=""
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">
                                    Organisation
                                  </span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    name="organization"
                                    id="outlined-basic"
                                    onChange={handleChange}
                                    required
                                    type="text"
                                    placeholder=""
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">Website</span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    required
                                    onChange={handleChange}
                                    name="website"
                                    type="text"
                                    placeholder=""
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>

                                <div className="mt-3">
                                  <span className="fw-600 fs-15">Linkedin</span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    type="text"
                                    placeholder=""
                                    className="w-100"
                                    name="linkedin"
                                    onChange={handleChange}
                                    variant="outlined"
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <LinkedInIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </div>

                                <div className="mt-3">
                                  <span className="fw-600 fs-15">Twitter</span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    type="text"
                                    placeholder=""
                                    className="w-100"
                                    name="twitter"
                                    onChange={handleChange}
                                    variant="outlined"
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <TwitterIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </div>

                                <div className="mt-3">
                                  <span className="fw-600 fs-15">Facebook</span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    type="text"
                                    placeholder=""
                                    className="w-100"
                                    name="facebook"
                                    onChange={handleChange}
                                    variant="outlined"
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <FacebookIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </div>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">About</span>
                                </div>
                                <div className="mt-2 ">
                                  <TextField
                                    id="outlined-basic"
                                    onChange={handleChange}
                                    type="text"
                                    required
                                    name="about"
                                    rows={5}
                                    multiline
                                    placeholder=""
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>

                                <div className="row my-3">
                                  <center>
                                    {" "}
                                    <button
                                      className="px-5 py-2 text-white bg-purple border-p br-5 "
                                      onClick={AddcruiterInfos}
                                    >
                                      Submit{" "}
                                    </button>
                                  </center>
                                </div>
                              </div>
                              <div className="col-sm-2"></div>
                            </div>
                          </div>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRecruiter;
