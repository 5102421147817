import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import styled from "styled-components";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomeLoader from "../../components/CustomeLoader";

const FunctionalArea = () => {
  const [functionalarea, setfunctionalarea] = useState([]);
  const [show, setshow] = useState(0);
  const [functionadata, setfunctionadata] = useState("");
  const [functionalareaid, setfunctionalareaid] = useState("");
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);



  // ------------------------------------------------------------------------------------------------

  // --------------------------------------------------UPDATE INDUSTRY------------------------------------
  const handleUpdate = async (e) => {
    e.preventDefault();
    axios
      .put("/api/update_functional_area/" + functionalareaid, {
        title: functionadata,
        headers: {
          Accept: "application/json",
        },
      })
      .then((result) => {
        window.location.reload(false);
      })
      .catch((error) => {
      });
  };

  // --------------------------------------------------ADD INDUSTRY------------------------------------


  const handleAddIndustry = async (e) => {
    e.preventDefault();
    const industryobj = new FormData();

    industryobj.append("title", functionadata);
    axios
      .post("/api/create_functional_area", industryobj, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((result) => {
        window.location.reload(false);
      })
      .catch((error) => {
      });
  };

  // --------------------------------------------------FETCH ALL INDUSTRY------------------------------------


  useEffect(() => {
    axios
      .get("/api/get_all_functional_area")
      .then((res) => {
        setfunctionalarea(res.data.data);
        console.log(res);
      })
      .catch((err) => {});
  }, []);

  // --------------------------------------------------DELETE SINGLE INDUSTRY------------------------------------


  const deleteHandler = (row) => {
    axios
      .delete("/api/delete_functional_area/" + row.id, {
        headers: {
          accept: "application/json",
        },
      })
      .then((result) => {
        let newArr = functionalarea.filter((items) => {
          return row.id !== items.id;
        });
        setfunctionalarea(newArr);
      })
      .catch((error) => {
      });
  };

  // --------------------------------------------------EDIT INDUSTRY ROW DATA------------------------------------


  const editHandler = (row) => {
    setshow(1);
    setfunctionadata(row.title);
    setfunctionalareaid(row.id);
  };
  const addhandle = () => {
    setshow(2);
  };

  // --------------------------------------------------------------------------------------------------------
 
  // ------------------------------------------------INDUSTRY TABLE COLUMN---------------------------------------
  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      width: "70px",
    },

    {
      name: "Functional Area",
      selector: (row) => <div>{row.title}</div>,
      sortable: true,
      width: "250px",
    },

    {
      name: "action",
      selector: (row) => (
        <div>
          <button
            onClick={() => editHandler(row)}
            className="bg-purple text-white border-p px-2 py-1 br-10 m-1"
          >
            <EditIcon />
          </button>
          <button
            className="bg-red text-white border-r px-2 py-1 br-10 m-1"
            onClick={() => { if (window.confirm('Are you sure you wish to delete this functional area?')) deleteHandler(row) }}

          >
            <DeleteIcon />
          </button>{" "}
        </div>
      ),
      width: "200px",
      sortable: false,
    },
  ];

  // --------------------------------------------------------------------------------------------------

  // ---------------------------------------------------NO LOGIN WITHOUT TOKEN--------------------------------

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  // -----------------------------------------------------------------------------------------------------------

  // ----------------------------------------------table data loading-----------------------------------------------

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRows(functionalarea);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  // -----------------------------------------------------FORM INPUT FIELD CSS------------------------------------

  const TextField = styled.input`
    height: 32px;
    width: 200px;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
      cursor: pointer;
    }
  `;

  // ----------------------------------------------------------------------------------------------------------

  // --------------------------------------------------------FILTER DATA FROM TABLE---------------------------------

  const filteredItems = functionalarea.filter(
    (item) =>
      item.title &&
      item.title.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  // ----------------------------------------------------------------------------------------------------------------

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-2 p-0 ">
            <Sidebar />
          </div>
          <div className="col-sm-10 ">
            <Navbar />

            <div className="container">
              <div className="row mt-2">
                <div className="col-sm-6 mt-2">
                  {show == 0 ? (
                    <>
                      {" "}
                      <TextField
                        type="text"
                        placeholder="Search...."
                        value={filterText}
                        autoFocus
                        onChange={(e) => setFilterText(e.target.value)}
                      />
                      <button
                        className="bg-purple text-white border-p m-1"
                        onClick={handleClear}
                      >
                        X
                      </button>{" "}
                    </>
                  ) : (
                    ""
                  )}
                  {show == 1 ? (
                    <div>
                      <span>Edit Functional Area :</span>{" "}
                      <input
                        type="text"
                        value={functionadata}
                        name="title"
                        onChange={(e) => setfunctionadata(e.target.value)}
                      />{" "}
                      <button
                        className="bg-purple text-white border-p "
                        onClick={handleUpdate}
                      >
                        Update
                      </button>
                    </div>
                  ) : (
                    ""
                  )}

                  {show == 2 ? (
                    <div>
                      <span>Add Functional Area :</span>{" "}
                      <input
                        type="text"
                        name="title"
                        onChange={(e) => setfunctionadata(e.target.value)}
                      />{" "}
                      <button
                        className="bg-purple text-white border-p "
                        onClick={handleAddIndustry}
                      >
                        Submit
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-sm-6">
                  <div className="mt-2 text-right ">
                    <button
                      className="bg-purple text-white border-p px-3 py-1 br-10 m-1"
                      onClick={addhandle}
                    >
                      + Add Functional Area
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="m-1">
              <DataTable
                columns={columns}
                data={filteredItems}
                defaultSortFieldId
                fixedHeaderScrollHeight="400px"
                fixedHeader
                responsive
                pagination={10}
                subHeaderAlign="right"
                highlightOnHover
                progressPending={pending}
                progressComponent={<CustomeLoader />}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FunctionalArea;
