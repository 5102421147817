import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import axios from "axios";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit";

const AddAdmin = () => {
  // ------------------------------------------------FETCH ALL LOCATIONS --------------------------------------------
  const [errormsg, seterrorMsg] = useState("");
  const [msg, setMsg] = useState("");
const [email ,setemail] = useState("");
const [password ,setpassword] = useState("");
const [passwordconfirm ,setpasswordconfirm] = useState("");

  const status =
    typeof window !== "undefined" ? localStorage.getItem("status") : null;

console.log(email)
console.log(password)
console.log(passwordconfirm)

  // ------------------------------------------------ADD NEW RECRUITER--------------------------------------------
  const AddAdim = async (e) => {
    e.preventDefault();
    const adminobj = new FormData();
    adminobj.append("email", email);
    adminobj.append("password", password);
    adminobj.append("password_confirmation", passwordconfirm);
    
    axios
      .post("/api/add_admin", adminobj, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((result) => {
        setMsg(result.data.message);
      })
      .catch((error) => {
        console.log(error);
        seterrorMsg(error.response.data.message);
      });
  };

  // -----------------------------------------------NO ACCESS WITHOUT TOKAN--------------------------------------------

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10 ">
          <div className="container-fluid">
            <Navbar />
            <div className="p-2 bg-white">
              <span>
                <Link to="/dashboard" className="text-d-none">
                  Dashboard
                </Link>{" "}/   <Link to="/admins" className="text-d-none">
                  Admin
                </Link>{" "}
                / Add
              </span>
            </div>
            <section
              className="bg-gre"
              style={{
                padding: "10px 0px 30px 0",
                minHeight: "570px",
              }}
            >
              {msg && <div className="alert alert-success">{msg}</div>}
              {errormsg && <div className="alert alert-danger">{errormsg}</div>}
              <MDBContainer
                className="px-5 mt-4"
                style={{ alignItems: "center" }}
              >
                <MDBRow>
                  <MDBCol lg="8" style={{ margin: "auto" }}>
                    <MDBCard className="mb-2 ">
                      <MDBCardBody>
                    
                        <MDBRow>
                          <div className="container m-0">
                            <div className="row p-3 m-0">
                              <div className="col-12 px-3">
                              <form onSubmit={AddAdim}>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">Email</span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    type="email"
                                    name="email"
                                    required
                                    onChange={(e) => setemail(e.target.value)}
                                    placeholder="enter your email"
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">Password</span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    type="password"
                                    name="password"
                                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,16}$"
                                    required
                                    onChange={(e) => setpassword(e.target.value)}
                                    placeholder="enter password"
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">
                                    Password Confirmation
                                  </span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    type="password"
                                    name="password_confirmation"
                                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,16}$"
                                    required
                                    onChange={(e) => setpasswordconfirm(e.target.value)}
                                    placeholder="re-enter password"
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>
                                <div className="row my-3">
                                  <center>
                                    {" "}
                                    <button
                                      className="px-5 py-2 text-white bg-purple border-p br-5 "
                                      type="submit"
                                    >
                                      Submit{" "}
                                    </button>
                                  </center>
                                </div>
                                </form>
                              </div>
                              <div className="col-sm-2"></div>
                            </div>
                          </div>
                        </MDBRow>
                       
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAdmin;
