import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import axios from "axios";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody
} from "mdb-react-ui-kit";

const EditSubscription = () => {
  const { id } = useParams();

  const [errormsg, seterrorMsg] = useState("");
  const [msg, setMsg] = useState("");
  const [title, settitle] = useState("");
  const [subsCription, setsubsCription] = useState("");
  const [price, setprice] = useState("");
  const [description, setdescrption] = useState("");
  const [Img, setImg] = useState({
    image: "",
  });

  const handleImage1 = (e) => {
    setImg({ image: e.target.files[0] });
  };

  // ------------------------------------FETCH SINGLE SUBSCRIPTION---------------------------------------
 

  useEffect(() => {
    axios.get("/api/get_single_subscription/" + id).then((res) => {
      settitle(res.data.data.title);
      setprice(res.data.data.price);
      setsubsCription(res.data.data);
      setdescrption(res.data.data.description);
    });
  }, [id]);

  // ------------------------------------EDIT SINGLE SUBSCRIPTION---------------------------------------


  const updatesubscription = async (e) => {
    e.preventDefault();
    const subscriptionobj = new FormData();
    subscriptionobj.append("title", title);
    subscriptionobj.append("price", price);
    subscriptionobj.append("description", description);
    subscriptionobj.append("image", Img.image);

    axios
      .post("/api/edit_subscription/" + id, subscriptionobj, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((result) => {
        setMsg(result.data.message);
      })
      .catch((error) => {
        seterrorMsg(error.response.data.message);
       
      });
  };

  // ------------------------------------NO ACCESS WITHOUT TOKAN---------------------------------------


  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("status")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-2 p-0">
          <Sidebar />
        </div>
        <div className="col-sm-10 ">
          <div className="container-fluid">
            <Navbar />
            <div className="p-2 bg-white">
              <span>
                <Link to="/dashboard" className="text-d-none">
                  Dashboard
                </Link>{" "}
                /{" "}
                <Link to="/subscription" className="text-d-none">
                  Subscription
                </Link>{" "}
                / Edit
              </span>
            </div>
            <section
              className="bg-gre"
              style={{
                padding: "10px 0px 30px 0",
                minHeight: "570px",
              }}
            >
              {msg && <div className="alert alert-success">{msg}</div>}
              {errormsg && <div className="alert alert-danger">{errormsg}</div>}
              <MDBContainer
                className="px-5 mt-4"
                style={{ alignItems: "center" }}
              >
                <MDBRow>
                  <MDBCol lg="8" style={{ margin: "auto" }}>
                    <MDBCard className="mb-2 ">
                      <MDBCardBody>
                        <MDBRow>
                          <div className="container m-0">
                            <div className="row p-3 m-0">
                              <div className="col-12 px-3">
                                <MDBRow>
                                  <MDBCol sm="4">
                                    <MDBCardText>Company Logo</MDBCardText>
                                  </MDBCol>
                                  <MDBCol sm="3">
                                    <MDBCardText className="text-muted">
                                      <img
                                        alt=""
                                        width="70"
                                        height="70"
                                        src={subsCription.image}
                                        style={{ borderRadius: "50%" }}
                                      />
                                    </MDBCardText>
                                  </MDBCol>

                                  <MDBCol sm="5">
                                    <MDBCardText className="text-muted">
                                      <input
                                        id="company"
                                        accept="image/*"
                                        onChange={handleImage1}
                                        required
                                        name="company_logo"
                                        type="file"
                                      />
                                    </MDBCardText>
                                  </MDBCol>
                                </MDBRow>

                                <div className="mt-5">
                                  <span className="fw-600 fs-15">Title</span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    type="text"
                                    name="title"
                                    value={title}
                                    onChange={(e) => settitle(e.target.value)}
                                    placeholder="enter title"
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>

                                <div className="mt-3">
                                  <span className="fw-600 fs-15">Price</span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    type="price"
                                    placeholder=""
                                    value={price}
                                    className="w-100"
                                    name="price"
                                    onChange={(e) => setprice(e.target.value)}
                                    variant="outlined"
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <CurrencyRupeeIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </div>
                                <div className="mt-3">
                                  <span className="fw-600 fs-15">
                                    Description
                                  </span>
                                </div>
                                <div className="mt-2">
                                  <TextField
                                    id="outlined-basic"
                                    onChange={(e) =>
                                      setdescrption(e.target.value)
                                    }
                                    type="text"
                                    required
                                    name="description"
                                    value={description}
                                    rows={5}
                                    multiline
                                    placeholder="enter the description of subscription"
                                    className="w-100"
                                    variant="outlined"
                                  />
                                </div>

                                <div className="row mt-5">
                                  <center>
                                    {" "}
                                    <button
                                      className="px-5 py-2 text-white bg-purple border-p br-5 "
                                      onClick={updatesubscription}
                                    >
                                      Update{" "}
                                    </button>
                                  </center>
                                </div>
                              </div>
                              <div className="col-sm-2"></div>
                            </div>
                          </div>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSubscription;
